import { Fragment, useContext, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { twMerge } from "tailwind-merge";
import classNames from "classnames";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { CountryData } from "../data/countryData";
import { AgeData } from "../data/ageData";
import { useNavigate } from "react-router-dom";
import { AbsUploader } from "../components/AbsUploader";
import { useAppContext } from "../hook/useApp.context";
import { useUserUpdateMutation } from "../app/apollo/type/graphql";
import { Photo } from "../components/Photo";
import { useUploader } from "../hook/useUploader";
import { Spinner } from "../components/Spinner";
import { L } from "../data/lang.static";
import { Paths } from "../data/const";
import { getRefetch } from "../utils/getRefetch";
import { USER_FIND_BY_NFC_ID } from "../apollo/gql/user";
import { getFromUrl } from "../utils/getFromUrl";

export const SignupPage = () => {
 const { user, refetchUser } = useAppContext();
 const navigate = useNavigate();
 const isUpdate = getFromUrl("mode");

 const [updateMu] = useUserUpdateMutation({
  awaitRefetchQueries: true,
  onCompleted({ UserUpdate }) {
   refetchUser().then(() => {
    if (UserUpdate.ok) navigate(isUpdate ? Paths["/mypage"] : Paths["/"]);
   });
  },
 });

 const images = "https://jw.stayjanda.cloud/keyband/keybandlogo  .png";
 const [country, setCountry] = useState(user?.country || "South Korea");
 const [age, setAge] = useState(user?.age || "");
 const [name, setName] = useState<string>(user?.name || "");
 const busiCardUploadHook = useUploader(user?.busiCard);
 const busiCard = busiCardUploadHook?.file;

 const inputSatistfied = !!age && !!name;

 const handleUpdate = () => {
  updateMu({
   variables: {
    input: {
     age,
     busiCard: busiCardUploadHook?.file,
     country,
     name,
     nfcId: user?.nfcId,
    },
    userId: user?._id!,
   },
  });
 };

 return (
  <div className="h-auto w-full mx-auto max-w-[768px] flex flex-col bg-neutral-100">
   <div className="w-full h-auto bg-indigo-500 rounded-b-[10vw] md:rounded-b-[70px] py-[11.1vw] md:py-20 px-[5.5vw] md:px-10">
    <h2 className="text-white text-[5.5vw] md:text-[40px] font-semibold">
     {L[isUpdate ? "ProfileUpdateText" : "SignUpWellComeText"]}
    </h2>
    <p className="text-white text-[min(3.8vw,24px)] md:text-[28px] font-normal mt-[1.5vw] md:mt-2.5 ">
     {L[isUpdate ? "ProfileUpdateSubText" : "SignUpWellComeSubText"]}
    </p>
    <div className="flex px-[2.7vw] py-[1.5vw] md:px-5 md:py-2.5 w-fit bg-indigo-300 rounded-[4.2vw] md:rounded-[30px] justify-center items-center gap-[5px] mt-[2.7vw] md:mt-5">
     <p className="text-white text-[2.7vw] md:text-lg font-semibold">
      NFC NO :
     </p>
     <p className="text-indigo-500 text-[2.7vw] md:text-lg font-semibold">
      {user?.nfcId}
     </p>
    </div>
   </div>
   <div className="flex flex-col px-[5.5vw] md:px-10 mt-[5.5vw] md:mt-[40px] mb-20 md:mb-48 gap-y-[5.5vw] md:gap-y-10">
    <div className="w-full p-[4.2vw] pt-[2.7vw] md:pt-[20px] md:p-[30px] bg-white rounded-[4.2vw] md:rounded-[30px]">
     <div className="flex items-center h-[8.8vw] md:h-16">
      <p className="text-neutral-700 text-[4.2vw] md:text-3xl font-semibold">
       {L["country_label"]}
       <span className="text-amber-400 text-[4.2vw] md:text-3xl font-semibold">
        *
       </span>
      </p>
     </div>
     <div className="relative w-full text-[4.2vw] bg-white text-neutral-700 font-normal md:text-3xl mt-[2.7vw] md:mt-[20px] md:h-auto rounded-[2.7vw] md:rounded-[20px] border border-neutral-200">
      <select
       value={country}
       onChange={(e) => {
        setCountry(e.currentTarget.value);
       }}
       className="outline-none border-none bg-transparent outline p-[2.7vw] pr-[1.5vw] md:p-[20px] md:pr-2.5 w-full flex justify-between items-center"
      >
       {country}
       {CountryData.map((coutry) => (
        <option selected={coutry.name === country} key={coutry.id}>
         {coutry.name}
        </option>
       ))}
      </select>
      <ChevronUpDownIcon className="absolute right-1 bg-white top-1/2 -translate-y-1/2 w-[6.6vw] h-[6.6vw] md:w-12 md:h-12" />
     </div>
    </div>
    <div className="w-full p-[4.2vw] pt-[2.7vw] md:pt-[20px] md:p-[30px] bg-white rounded-[4.2vw] md:rounded-[30px]">
     <div className="flex items-center h-[8.8vw] md:h-16">
      <p className="text-neutral-700 text-[4.2vw] md:text-3xl font-semibold">
       {L["age_range_label"]}
       <span className="text-amber-400 text-[4.2vw] md:text-3xl font-semibold">
        *
       </span>
      </p>
     </div>
     <div className="relative w-full text-[4.2vw] bg-white text-neutral-700 font-normal md:text-3xl mt-[2.7vw] md:mt-[20px] md:h-auto rounded-[2.7vw] md:rounded-[20px] border border-neutral-200">
      <select
       value={age}
       onChange={(e) => {
        setAge(e.currentTarget.value as any);
       }}
       className="outline-none border-none bg-transparent outline p-[2.7vw] pr-[1.5vw] md:p-[20px] md:pr-2.5 w-full flex justify-between items-center"
      >
       {age}
       {AgeData.map((agedata) => (
        <option key={agedata.id}>{agedata.range}</option>
       ))}
      </select>
      <ChevronUpDownIcon className="absolute right-1 bg-white top-1/2 -translate-y-1/2 w-[6.6vw] h-[6.6vw] md:w-12 md:h-12" />
     </div>
    </div>
    <div className="w-full p-[4.2vw] pt-[2.7vw] md:pt-[20px] md:p-[30px] bg-white rounded-[4.2vw] md:rounded-[30px]">
     <div className="flex items-center h-[8.8vw] md:h-16">
      <p className="text-neutral-700 text-[4.2vw] md:text-3xl font-semibold">
       {L["Nickname"]}
       <span className="text-amber-400 text-[4.2vw] md:text-3xl font-semibold">
        *
       </span>
      </p>
     </div>
     <div className="mt-[2.7vw] md:mt-[20px] md:h-[88px] w-full rounded-[2.7vw] md:rounded-[20px] flex items-center justify-between border border-neutral-200 ">
      <input
       maxLength={24}
       className="text-neutral-700 bg-transparent text-[4.2vw] md:text-3xl font-normal w-full outline-none p-[2.7vw] md:p-[20px]"
       value={name}
       onChange={(e) => {
        setName(e.currentTarget.value);
       }}
      />
     </div>

     {name.length > 23 && (
      <p className="text-rose-500 px mt-[1.1vw] md:mt-2 text-[2.2vw] md:text-lg">
       * {L["maximumNickNameLengthIs"]}
      </p>
     )}
    </div>
    <div className="w-full p-[4.2vw] pt-[2.7vw] md:pt-[20px] md:p-[30px] bg-white rounded-[4.2vw] md:rounded-[30px]">
     <div className="flex items-center h-[8.8vw] md:h-16">
      <p className="text-neutral-700 text-[4.2vw] md:text-3xl font-semibold">
       {L["Business_card"]}
      </p>
     </div>
     <AbsUploader
      {...busiCardUploadHook}
      className="relative mt-[2.7vw] p-[2.7vw] md:p-2.5 overflow-hidden  md:mt-[20px] h-[44.4vw] md:h-[320px] w-full rounded-[2.7vw] md:rounded-[20px] flex items-center justify-center border border-neutral-200 "
     >
      {!busiCard || busiCardUploadHook.loading ? (
       <div className=" overflow-auto flex flex-col  items-center max-w-full max-h-full">
        {busiCardUploadHook.loading ? (
         <Spinner className="w-[8.3vw] h-[8.3vw] md:w-[60px] md:h-[60px]" />
        ) : (
         <svg
          className="w-[8.3vw] h-[8.3vw] md:w-[60px] md:h-[60px]"
          xmlns="http://www.w3.org/2000/svg"
          width="60"
          height="60"
          viewBox="0 0 60 60"
          fill="none"
         >
          <path
           d="M30 50H12.5C11.1739 50 9.90215 49.4732 8.96447 48.5355C8.02678 47.5979 7.5 46.3261 7.5 45V22.5C7.5 21.1739 8.02678 19.9021 8.96447 18.9645C9.90215 18.0268 11.1739 17.5 12.5 17.5H15C16.3261 17.5 17.5979 16.9732 18.5355 16.0355C19.4732 15.0979 20 13.8261 20 12.5C20 11.837 20.2634 11.2011 20.7322 10.7322C21.2011 10.2634 21.837 10 22.5 10H37.5C38.163 10 38.7989 10.2634 39.2678 10.7322C39.7366 11.2011 40 11.837 40 12.5C40 13.8261 40.5268 15.0979 41.4645 16.0355C42.4021 16.9732 43.6739 17.5 45 17.5H47.5C48.8261 17.5 50.0979 18.0268 51.0355 18.9645C51.9732 19.9021 52.5 21.1739 52.5 22.5V31.25M40 47.5H55M47.5 40V55"
           stroke="#CCCCCC"
           stroke-width="2"
           stroke-linecap="round"
           stroke-linejoin="round"
          />
          <path
           d="M22.5 32.5C22.5 34.4891 23.2902 36.3968 24.6967 37.8033C26.1032 39.2098 28.0109 40 30 40C31.9891 40 33.8968 39.2098 35.3033 37.8033C36.7098 36.3968 37.5 34.4891 37.5 32.5C37.5 30.5109 36.7098 28.6032 35.3033 27.1967C33.8968 25.7902 31.9891 25 30 25C28.0109 25 26.1032 25.7902 24.6967 27.1967C23.2902 28.6032 22.5 30.5109 22.5 32.5Z"
           stroke="#CCCCCC"
           stroke-width="2"
           stroke-linecap="round"
           stroke-linejoin="round"
          />
         </svg>
        )}
        <p className="text-neutral-400 text-[4.2vw] md:text-3xl font-normal md:mt-2.5 mt-[1.5vw]">
         {busiCardUploadHook.loading ? L["Uploading"] : L["Please_Upload"]}
        </p>
       </div>
      ) : (
       <Photo
        className="object-contain rounded-[2.7vw] w-full h-full md:rounded-[20px]"
        src={busiCard?.uri}
        alt={"card image"}
       />
      )}
     </AbsUploader>
    </div>
   </div>
   <button
    className={twMerge(
     classNames(
      "fixed bottom-0 w-full max-w-[768px] bg-amber-400 py-[4.2vw] md:py-[30px]",
      {
       ["bg-neutral-200  pointer-events-none"]: !inputSatistfied,
      }
     )
    )}
    onClick={() => {
     handleUpdate();
    }}
   >
    <p className="text-white text-[4.2vw] md:text-3xl font-semibold">Done!</p>
   </button>
  </div>
 );
};

export default SignupPage;
