import { useState } from "react";
import { FfileFragment } from "../app/apollo/type/graphql";

export type TUseUploader = ReturnType<typeof useUploader>;
export const useUploader = (
 defaultFiles?: FfileFragment | FfileFragment[] | undefined | null
) => {
 const [loading, setLoading] = useState(false);
 const [files, setFiles] = useState<FfileFragment[] | undefined>(
  defaultFiles
   ? Array.isArray(defaultFiles)
     ? defaultFiles
     : [defaultFiles]
   : undefined
 );

 const setFile = (file: FfileFragment) => {
  setFiles([file]);
 };

 return {
  setFile,
  onLoading: setLoading,
  loading,
  file: files?.[0],
  files: files || [],
  setFiles,
 };
};
