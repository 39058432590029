import { Category } from "../app/apollo/type/graphql";

export const DEFAULT_PAGE_INFO = {
 currentPage: 0,
 rowCount: 0,
 totalPage: 0,
};

export const PATH_PREFIX = "southkorea/busan/wbc/";

export enum Paths {
 "/" = `/${PATH_PREFIX}`,
 "/err" = `/${PATH_PREFIX}err`,
 "/visit" = `/${PATH_PREFIX}visit`,
 "/list" = `/${PATH_PREFIX}list`,
 "/signup" = `/${PATH_PREFIX}signup`,
 "/place" = `/${PATH_PREFIX}place`,
 "/map" = `/${PATH_PREFIX}map`,
 "/mypage" = `/${PATH_PREFIX}mypage`,
 "/album" = `/${PATH_PREFIX}album`,
 "/search" = `/${PATH_PREFIX}search`,
}

export const CategoryColor: Record<Category, string> = {
 Attractions: "teal",
 Cafe: "red",
 Convention: "amber",
};

export const CategoryImgMap: Record<Category, string> = {
 Attractions: "https://jw.stayjanda.cloud/keyband/attractions_Icon.svg",
 Cafe: "https://jw.stayjanda.cloud/keyband/cafe_Icon.svg",
 Convention: "https://jw.stayjanda.cloud/keyband/convention_Icon.svg",
};
export const Airplan = "https://jw.stayjanda.cloud/keyband/airplane_icon.svg";
