import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import { Photo } from "../components/Photo";
import { useAppContext } from "../hook/useApp.context";
import { useNavigate } from "react-router-dom";
import { Airplan, CategoryImgMap, Paths } from "../data/const";
import { CACHE, L, LagnList, LangPair, LangSet } from "../data/lang.static";
import { systemNotiListHook } from "../hook/useSystemNoti";
import { yyyymmddHHmm } from "../utils/date";
import { notiContentGen } from "../utils/noti";
import { useUploader } from "../hook/useUploader";
import {
 _FileSort,
 _SystemNotiSort,
 useLangSetMutation,
} from "../app/apollo/type/graphql";
import { useState } from "react";
import { apolloClient } from "../apollo";
import { ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { LocalStorage } from "../utils/localstorage/StorageAddition";
import { ITEM_LIST } from "../apollo/gql/item";
import { getRefetch } from "../utils/getRefetch";

export const MyPage: React.FC = () => {
 const context = useAppContext();
 const user = context.user;
 const [openSetting, setOpenSetting] = useState(false);

 const [chnageLangMu] = useLangSetMutation();

 const uploaderHook = useUploader();
 const navigate = useNavigate();
 const { items: histories, getLoading: notiLoadings } = systemNotiListHook({
  initialSort: [_SystemNotiSort.CreatedAtDesc],
  initialViewCount: 99,
 });
 //  const { items: files, getLoading: fileLoadings } = useFileList({
 //   fixingFilter: {
 //    userId__eq: user?._id,
 //   },
 //   initialSort: [_FileSort.CreatedAtDesc],
 //  });

 const toEdit = () => {
  navigate(Paths["/signup"] + "?mode=update");
 };

 //  const handlePopup = () => {
 //   setOpenSetting(!openSetting);
 //  };
 return (
  <div className="flex flex-col w-full max-w-[768px] mx-auto h-auto min-h-screen bg-neutral-100">
   <div className="w-full h-auto flex flex-col items-start bg-indigo-500 rounded-b-[9.7vw] md:rounded-b-[70px] p-[5.5vw] md:px-[60px] pt-[11.1vw] md:pt-20 pb-[10.2vw] md:pb-[70px]">
    <div className="flex items-center justify-between w-full relative">
     <p className="text-white text-[5.5vw] md:text-[40px] font-bold">
      {user?.name}
     </p>
     <Cog6ToothIcon
      className="w-[6.6vw] h-[6.6vw] md:w-12 md:h-12 text-white"
      onClick={() => {
       setOpenSetting(true);
      }}
     />
     {openSetting ? (
      <div className="absolute w-full bg-white h-auto py-[2.7vw] pl-[4.2vw] pr-[2.7vw] md:py-5 md:px-[30px] top-[9.7vw] md:rounded-[20px] rounded-[2.7vw] z-40 shadow-[0_0px_10px_rgba(0,0,0,0.1)]">
       <div className="w-full flex items-center justify-between h-[8.8vw] md:h-16">
        <h2 className="text-neutral-800 text-[4.4vw] md:text-[32px] font-semibold">
         {L["Setting"]}
        </h2>
        <Photo
         width={64}
         height={64}
         className="h-[8.8vw] w-[8.8vw] md:w-16 md:h-16 text-neutral-400"
         onClick={() => {
          setOpenSetting(false);
         }}
         src={"https://jw.stayjanda.cloud/keyband/X_button.svg"}
         alt={"x"}
        />
       </div>
       <div className="mt-[2.7vw] md:mt-5 py-[1.5vw] md:py-[10px] gap-x-[2.7vw] md:gap-x-5 flex items-center">
        <Photo
         width={48}
         height={48}
         src={"https://jw.stayjanda.cloud/keyband/edit_person.svg"}
         alt={"profile"}
         className="w-[6.6vw] h-[6.6vw] md:w-12 md:h-12"
        />
        <div
         onClick={() => {
          toEdit();
         }}
         className="flex flex-col gap-y-[0.8vw]"
        >
         <p className="text-neutral-700 text-[3.9vw] md:text-[28px] font-semibold">
          {L["Edit_Profile"]}
         </p>
         <p className="text-neutral-700 text-[3.3vw] md:text-2xl font-normal leading-none">
          {L["ProfileUpdateText"]}
         </p>
        </div>
       </div>
       <div className="mt-[2.7vw] md:mt-5 py-[1.5vw] md:py-[10px] gap-x-[2.7vw] md:gap-x-5 flex items-center">
        <Photo
         width={48}
         height={48}
         src={"https://jw.stayjanda.cloud/keyband/lang_symbol.svg"}
         alt={"profile"}
         className="w-[6.6vw] h-[6.6vw] md:w-12 md:h-12"
        />
        <div className="relative w-full">
         <div className="flex flex-col gap-y-[0.8vw]">
          <p className="text-neutral-700 text-[3.9vw] md:text-[28px] font-semibold">
           {L["Language"]}
          </p>
          <p className="text-neutral-700 text-[3.3vw] md:text-2xl font-normal leading-none">
           {L["Language_Desc"]}
          </p>
         </div>
         <select
          onChange={(e) => {
           const val = e.currentTarget.value;
           apolloClient.clearStore();
           chnageLangMu({
            variables: {
             langCode: val,
            },
           }).then(() => {
            CACHE.langCode = val;
            LocalStorage.set("lang", val);
            LangSet(val);
            context.setUpdateKey(context.updateKey + 1);
            setOpenSetting(false);
           });
          }}
          className="opacity-0 absolute left-0 top-0 bottom-0 right-0 text-[4.2vw] bg-white text-neutral-700 font-normal md:text-3xl  outline-none border-none bg-transparent outline p-[2.7vw] pr-[1.5vw] md:p-[20px] md:pr-2.5 w-full flex justify-between items-center"
         >
          {LagnList.map((li) => {
           return (
            <option selected={context.langCode === li} value={li}>
             {LangPair[li as keyof typeof LangPair]}
            </option>
           );
          })}
         </select>
         {/* <ChevronUpDownIcon className="absolute right-1 bg-white top-1/2 -translate-y-1/2 w-[6.6vw] h-[6.6vw] md:w-12 md:h-12" /> */}
        </div>
       </div>
      </div>
     ) : null}
    </div>
    <p className="text-white text-[3.9vw] md:text-[28px] font-normal md:leading-7 mt-[1.5vw] md:mt-2.5">
     {user?.country} | {user?.age}
    </p>
    <div className="flex px-[2.7vw] py-[1.5vw] md:px-5 md:py-2.5 w-fit bg-indigo-300 rounded-[4.2vw] md:rounded-[30px] justify-center items-center gap-[1.5vw] md:gap-x-2.5 mt-[2.7vw] md:mt-[30px] ">
     <p className="text-white text-[2.7vw] md:text-lg font-semibold">
      NFC NO :
     </p>
     <p className="text-indigo-500 text-[2.7vw] md:text-lg font-semibold">
      {user?.nfcId}
     </p>
    </div>
   </div>
   <div className="pt-[5.5vw] md:pt-10 px-[5.5vw] md:px-10 pb-[27.7vw] md:pb-[200px] w-full">
    {!notiLoadings && (
     <div className="flex flex-col w-full items-start mt-[5.5vw] md:mt-10">
      <div className="flex w-full h-[8.8vw] md:h-16 items-center justify-between">
       <p className="text-neutral-700 text-[4.44vw] md:text-[28px] font-bold">
        {L["My_HISTORY"]}
       </p>
       {/* <p className="text-indigo-500 text-[3.9vw] md:text-[28px] font-semibold underline">
       See All
      </p> */}
      </div>
      <div className="mt-[2.7vw] md:mt-5 w-full flex flex-col gap-y-[2.7vw] md:gap-y-[30px]">
       {histories.map((his) => {
        return (
         <div key={his._id + "HistoryCell"}>
          <div className="flex items-center w-full break-all   px-[5.5vw] md:px-10 py-[3.4vw] md:py-[25px] gap-x-[2.7vw] md:gap-x-5 rounded-t-[4.2vw] md:rounded-t-[30px] bg-white">
           <Photo
            width={40}
            height={40}
            //@ts-ignore
            src={CategoryImgMap[his.info?.itemCategory as Category] || Airplan}
            alt={""}
            className="h-[6.66vw] w-[6.66vw] md:h-10 md:w-10 text-indigo-500"
           />
           <p className="text-[4.44vw] md:text-4xl font-semibold text-neutral-700">
            {notiContentGen(his, context)}
           </p>
          </div>
          <div className="flex items-center w-full h-[12.5vw] md:h-[90px] px-[5.5vw] md:px-10 py-[3.4vw] md:py-[25px] rounded-b-[4.2vw] md:rounded-b-[30px] bg-white border-t border-gray-100">
           <p className="ml-[8.7vw] md:ml-[63px] text-[3.9vw] md:text-[28px] font-normal text-neutral-700">
            {yyyymmddHHmm(his.createdAt)}
           </p>
          </div>
         </div>
        );
       })}
      </div>
     </div>
    )}
   </div>
  </div>
 );
};

export default MyPage;

// {!fileLoadings && (
//     <div className="flex flex-col w-full">
//      <div className="flex w-full h-[8.8vw] md:h-16 items-center justify-between">
//       <p className="text-neutral-700 text-[4.44vw] md:text-[28px] font-bold">
//        {L["Shorts"]}
//       </p>
//       <p
//        onClick={() => {}}
//        className="text-indigo-500 text-[4.44vw] md:text-[28px] font-semibold underline"
//       >
//        {L["See_All"]}
//       </p>
//      </div>
//      <div className="flex flex-nowrap overflow-auto md:rounded-[30px]  p-[2.7vw] md:p-5  mt-[2.7vw] md:mt-5 gap-[5.5vw] md:gap-10">
//       {files.map((file) => {
//        return (
//         <div
//          key={file._id}
//          className="w-full h-[55.5vw]  min-w-[44.5%]  md:h-[400px] relative flex flex-col items-end justify-end"
//         >
//          <Photo
//           className="absolute w-full h-full object-cover rounded-[4.2vw] md:rounded-[30px]"
//           file={file}
//           alt={"shortsphoto"}
//          />
//          {/* <div className="flex items-center w-auto py-[0.8vw] px-[1.5vw] md:py-1.5 md:px-2.5 z-10 gap-x-[1.5vw] md:gap-x-2.5 bg-amber-400 rounded-[0.8vw] md:rounded-md">
//           <Photo
//            width={20}
//            height={20}
//            className="w-[2.7vw] h-[2.7vw] md:w-5 md:h-5"
//            src={"https://jw.stayjanda.cloud/keyband/eye_white_icon.svg"}
//            alt={"eye"}
//           />
//           <p className="text-white text-[2.5vw] md:text-lg font-medium">
//            23,452
//           </p>
//          </div> */}
//         </div>
//        );
//       })}
//       <AbsUploader
//        multiple
//        className="border min-w-[44.5%] w-full rounded-[4.2vw] md:rounded-[30px]   h-[55.5vw] md:h-[400px]"
//        {...uploaderHook}
//       >
//        <div className="flex justify-center items-start flex-col h-full text-center">
//         <svg
//          className="mx-auto w-[8.3vw] h-[8.3vw] md:w-[60px] md:h-[60px]"
//          xmlns="http://www.w3.org/2000/svg"
//          width="60"
//          height="60"
//          viewBox="0 0 60 60"
//          fill="none"
//         >
//          <path
//           d="M30 50H12.5C11.1739 50 9.90215 49.4732 8.96447 48.5355C8.02678 47.5979 7.5 46.3261 7.5 45V22.5C7.5 21.1739 8.02678 19.9021 8.96447 18.9645C9.90215 18.0268 11.1739 17.5 12.5 17.5H15C16.3261 17.5 17.5979 16.9732 18.5355 16.0355C19.4732 15.0979 20 13.8261 20 12.5C20 11.837 20.2634 11.2011 20.7322 10.7322C21.2011 10.2634 21.837 10 22.5 10H37.5C38.163 10 38.7989 10.2634 39.2678 10.7322C39.7366 11.2011 40 11.837 40 12.5C40 13.8261 40.5268 15.0979 41.4645 16.0355C42.4021 16.9732 43.6739 17.5 45 17.5H47.5C48.8261 17.5 50.0979 18.0268 51.0355 18.9645C51.9732 19.9021 52.5 21.1739 52.5 22.5V31.25M40 47.5H55M47.5 40V55"
//           stroke="#CCCCCC"
//           stroke-width="2"
//           stroke-linecap="round"
//           stroke-linejoin="round"
//          />
//          <path
//           d="M22.5 32.5C22.5 34.4891 23.2902 36.3968 24.6967 37.8033C26.1032 39.2098 28.0109 40 30 40C31.9891 40 33.8968 39.2098 35.3033 37.8033C36.7098 36.3968 37.5 34.4891 37.5 32.5C37.5 30.5109 36.7098 28.6032 35.3033 27.1967C33.8968 25.7902 31.9891 25 30 25C28.0109 25 26.1032 25.7902 24.6967 27.1967C23.2902 28.6032 22.5 30.5109 22.5 32.5Z"
//           stroke="#CCCCCC"
//           stroke-width="2"
//           stroke-linecap="round"
//           stroke-linejoin="round"
//          />
//         </svg>
//         <p className="text-neutral-400 text-center w-full text-[3.2vw] md:text-2xl font-normal md:mt-2.5 mt-[1.5vw]">
//          {uploaderHook.loading ? L["Uploading"] : L["Please_Upload"]}
//         </p>
//        </div>
//       </AbsUploader>
//      </div>
//     </div>
//    )}
