export const s4 = () => {
 return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
};

export function makeid(length: number) {
 var result = "";
 var characters =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
 var charactersLength = characters.length;
 for (var i = 0; i < length; i++) {
  result += characters.charAt(Math.floor(Math.random() * charactersLength));
 }
 return result;
}

export const s8 = makeid.bind(makeid, 8);
export const s16 = makeid.bind(makeid, 16);
export const s32 = makeid.bind(makeid, 32);
