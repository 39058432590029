import { gql } from "@apollo/client";
import {
 F_COLLECTION_DATA_INTERFACE,
 F_INPUTERROR,
 F_PAGEINFO,
} from "./common";
import { F_FILE } from "./file";
import { F_VISIT } from "./visit";
import { F_COUPON } from "./coupon";

export const F_USER = gql`
 fragment Fuser on User {
  ...FcollectionDataInterface
  unReadSystemNoties
  type
  nfcId
  country
  age
  name
  signed
  langeCode
  busiCard {
   ...Ffile
  }
  Visits {
   itemId
  }
  useCoupon {
   ...Fcoupon
  }
  visitCnt
 }
 ${F_COUPON}
 ${F_FILE}
 ${F_COLLECTION_DATA_INTERFACE}
`;

export const USER_CREATE = gql`
 query userCreateOrFind($input: UserInput!) {
  UserCreateOrFind(input: $input) {
   ok
   error {
    ...FinputError
   }
   data {
    ...Fuser
   }
  }
 }
 ${F_USER}
 ${F_INPUTERROR}
`;

export const USER_FIND_BY_NFC_ID = gql`
 query userFindByNfcId($nfcId: String!) {
  UserFindByNfcId(nfcId: $nfcId) {
   ...Fuser
  }
 }
 ${F_USER}
 ${F_INPUTERROR}
`;

export const USER_UPDATE = gql`
 mutation userUpdate($input: UserInput!, $userId: String!) {
  UserUpdate(userId: $userId, input: $input) {
   ok
   error {
    ...FinputError
   }
  }
 }
 ${F_INPUTERROR}
`;

export const USER_DELETE = gql`
 mutation userDelete($userId: String!) {
  UserDelete(userId: $userId) {
   ok
   error {
    ...FinputError
   }
  }
 }
 ${F_INPUTERROR}
`;

export const LANG_SET = gql`
 mutation langSet($langCode: String!) {
  LangSet(langCode: $langCode) {
   ok
   error {
    ...FinputError
   }
  }
 }
 ${F_INPUTERROR}
`;

export const USER_LIST = gql`
 query userList(
  $sort: [_UserSort!]
  $filter: _UserFilter
  $pagingInput: OffsetPagingInput!
 ) {
  UserList(sort: $sort, pagingInput: $pagingInput, filter: $filter) {
   pageInfo {
    ...FoffsetPagingInfo
   }
   items {
    ...Fuser
   }
  }
 }
 ${F_USER}
 ${F_PAGEINFO}
`;
