import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Paths } from "../data/const";
import { AppContext } from "../hook/useApp.context";
export default function Page404() {
 const { redirectToAuth } = useContext(AppContext);
 const navigate = useNavigate(); // Get the navigate function

 useEffect(() => {
  navigate(redirectToAuth ? Paths["/signup"] : Paths["/"]); // Use navigate to change the route
 }, [navigate, redirectToAuth]); // Include navigate in the dependency array

 return null;
}
