import classNames from "classnames";
import { useEffect, useState } from "react";
import { Photo } from "../../../components/Photo";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../../data/const";
import { useLocation } from "react-router-dom";
import {
 GiftIcon,
 HomeIcon,
 MapIcon,
 UserIcon,
} from "@heroicons/react/24/solid";
import { L } from "../../../data/lang.static";

interface IProp {
 path: Paths;
 Icon: React.FC<any>;
 title: string;
}

const NavButton: React.FC<IProp> = ({ path, title, Icon }) => {
 const location = useLocation();
 const navigate = useNavigate();
 return (
  <div
   onClick={() => {
    navigate(path!);
   }}
   className={classNames("flex flex-col items-center text-neutral-500 ", {
    ["!text-indigo-500 font-semibold"]: location.pathname === path,
   })}
  >
   <Icon className="w-[6.6vw] h-[6.6vw] md:w-10 md:h-10" />
   <p className={classNames("text-[2.7vw] md:text-[22px] mt-1 ")}>{title}</p>
  </div>
 );
};

export const Footer = () => {
 return (
  <div className="fixed bottom-0 w-full max-w-[768px] z-[9999] bg-white grid grid-cols-4 items-center py-2.5 md:py-5 border-t border-neutral-200">
   <NavButton Icon={HomeIcon} title={L["HOME"]} path={Paths["/"]} />
   <NavButton Icon={GiftIcon} title={L["ALBUMS"]} path={Paths["/album"]} />
   <NavButton Icon={MapIcon} title={L["MAP"]} path={Paths["/map"]} />
   <NavButton Icon={UserIcon} title={L["MY"]} path={Paths["/mypage"]} />
  </div>
 );
};

export default Footer;
