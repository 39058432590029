import { useContext } from "react";
import { AppContext } from "../hook/useApp.context";
import { useItemFindByIdQuery } from "../app/apollo/type/graphql";
import { useParams, useNavigate } from "react-router-dom";
import { L } from "../data/lang.static";
import { Photo } from "../components/Photo";
import { twMerge } from "tailwind-merge";
import classNames from "classnames";
import { Paths } from "../data/const";

export const Visit: React.FC = () => {
 const navigate = useNavigate();
 let { id } = useParams<{ id: string }>();
 if (!id) throw Error("no id detected");
 const { data } = useItemFindByIdQuery({
  variables: {
   itemId: id,
   selectAll: false,
  },
 });
 const { lang } = useContext(AppContext);
 const item = data?.ItemFindById;
 return (
  <div className="pt-40 min-h-screen text-center">
   <div className="w-72 h-56 flex-col justify-start items-center gap-10 inline-flex">
    <div className="text-center text-neutral-800 text-2xl font-bold  leading-loose">
     {L["VisitMessage"]}
    </div>
    <div className="flex-col justify-center items-center gap-2.5 flex">
     <div className="w-28 h-28 rounded-lg overflow-hidden">
      <Photo
       className="object-cover w-full h-full"
       size={200}
       src={item?.image?.[0]?.uri}
      />
     </div>
     <div className="text-center text-neutral-700 text-lg font-bold  leading-normal">
      {lang(item?.name)}
     </div>
    </div>
   </div>
   <button
    style={{
     zIndex: 80,
    }}
    className={twMerge(
     classNames(
      "z-50 fixed bottom-0 w-full left-0 right-0 max-w-[768px] bg-amber-400 py-[4.2vw] md:py-[30px]"
     )
    )}
    onClick={() => {
     navigate(Paths["/"]);
    }}
   >
    <p className="text-white text-[4.2vw] md:text-3xl font-semibold">Done!</p>
   </button>
  </div>
 );
};
