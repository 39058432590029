import { gql } from "@apollo/client";
import { F_FILE } from "./file";

export const F_BOUCHER = gql`
 fragment Fboucher on Boucher {
  title
  subText
  file {
   ...Ffile
  }
 }
 ${F_FILE}
`;
