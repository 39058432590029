import { L } from "../data/lang.static";
import { NoSymbolIcon } from "@heroicons/react/24/outline";

interface IProp {
 title: string;
}
export const ErrorPage: React.FC<IProp> = ({ title }) => {
 return (
  <div className="p-4 w-full min-h-svh flex items-center justify-center flex-col">
   <h1 className="mb-10 text-indigo-500 font-bold text-2xl md:text-4xl">
    {title}
   </h1>
   <NoSymbolIcon className="text-indigo-500 w-[13.2vw] h-[13.2vw] md:w-24 md:h-24 " />
  </div>
 );
};
