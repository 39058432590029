import { useState } from "react";
import { PhotoIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Button } from "./button";
import { ImageDropzone } from "./ImageDropZone";
import { Spinner } from "./Spinner";
import { FfileFragment } from "../app/apollo/type/graphql";
import { TUseUploader } from "../hook/useUploader";

interface IProp extends React.HTMLProps<HTMLDivElement>, TUseUploader {
 noImg?: boolean;
}

export const AbsUploader: React.FC<IProp> = ({
 files,
 setFile,
 setFiles,
 className,
 children,
 onLoading,
 onProgress,
 noImg,
 ...props
}) => {
 return (
  <ImageDropzone
   onUploaded={(file) => {
    setFile(file);
   }}
   onUploadeds={(newFiles) => {
    setFiles([...files, ...newFiles]);
   }}
   setLoading={onLoading as any}
   className={className}
   resize={{
    width: 500,
   }}
   maxFileLength={10 - (files?.length || 0)}
   {...props}
   accept={"image/*"}
  >
   {children}
  </ImageDropzone>
 );
};
