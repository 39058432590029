import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function useScrollToTopByPathChange() {
 const { pathname } = useLocation();

 useEffect(() => {
  const move = () => {
   window.scrollTo(0, 0);
  };
  move();
 }, [pathname]);

 return {};
}
