import { AppContext, TUseAppContext } from "./../hook/useApp.context";
import {
 FsystemNotiFragment,
 SystemNotiType,
} from "../app/apollo/type/graphql";

export const notiContentGen = (
 noti: FsystemNotiFragment,
 context: TUseAppContext
) => {
 const getLang = context.lang;
 const lang = context.langCode;
 const name = context.user?.name || "";
 const itemName = getLang(noti.info?.itemName);
 const couponName = getLang(noti.info?.couponName);
 if (noti.type === SystemNotiType.Visit)
  switch (lang) {
   case "ko":
    return itemName + "에 방문 하셨어요.";
   case "en":
    return `Visited at ${itemName}`;
   case "zh-Hans":
    return `访问了${itemName}`;
   case "de":
    return `Besucht bei ${itemName}`;
   case "ja":
    return `${itemName}に訪問しました`;
   case "hi":
    return `${itemName} पर आपका स्वागत है`;
   case "fr":
    return `Visité à ${itemName}`;
  }

 if (noti.type === SystemNotiType.Coupon)
  switch (lang) {
   case "ko":
    return "쿠폰 " + couponName + "을 사용하셨습니다.";
   case "en":
    return `You have used coupon [${couponName}]`;
   case "zh-Hans":
    return `您已使用优惠券[${couponName}]`;
   case "de":
    return `Sie haben den Gutschein [${couponName}] verwendet`;
   case "ja":
    return `クーポン[${couponName}]を使用しました`;
   case "hi":
    return `आपने कूपन [${couponName}] का उपयोग किया है`;
   case "fr":
    return `Vous avez utilisé le coupon [${couponName}]`;
  }
 if (noti.type === SystemNotiType.Start)
  switch (lang) {
   case "ko":
    return `안녕하세요, ${name}님! Key Band 서비스에 가입해 주셔서 감사합니다.`;
   case "en":
    return `Wellcome, ${name}!`;
   case "zh-Hans":
    return `欢迎, ${name}! 感谢您加入Key Band服务。`;
   case "de":
    return `Willkommen, ${name}! Vielen Dank, dass Sie dem Key Band-Service beigetreten sind.`;
   case "ja":
    return `ようこそ, ${name}さん! Key Bandサービスにご参加いただきありがとうございます。`;
   case "hi":
    return `स्वागत है, ${name}! Key Band सेवा में शामिल होने के लिए धन्यवाद।`;
   case "fr":
    return `Bienvenue, ${name}! Merci de vous être inscrit au service Key Band.`;
  }
 return "";
};

export const hiGen = (context: TUseAppContext) => {
 const getLang = context.lang;
 const lang = context.langCode;
 const name = context.user?.name || "";
 switch (lang) {
  case "ko":
   return "안녕하세요! " + name + "님";
  case "en":
   return `Hi, ${name}!`;
  case "zh-Hans":
   return `你好, ${name}!`;
  case "de":
   return `Hallo, ${name}!`;
  case "ja":
   return `こんにちは, ${name}さん!`;
  case "hi":
   return `नमस्ते, ${name}!`;
  case "fr":
   return `Salut, ${name}!`;
  default:
   return `Hi, ${name}!`; // Default message
 }
};
