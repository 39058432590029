export const AgeData = [
 {
  id: 0,
  range: "Selecct Age",
 },
 {
  id: 1,
  range: "1 ~ 13",
 },
 {
  id: 2,
  range: "14 ~ 16",
 },
 {
  id: 3,
  range: "17 ~ 19",
 },
 {
  id: 4,
  range: "20 ~ 29",
 },
 {
  id: 5,
  range: "30 ~ 39",
 },
 {
  id: 6,
  range: "40 ~ 49",
 },
 {
  id: 7,
  range: "50 ~ 59",
 },
 {
  id: 8,
  range: "60 ~ 69",
 },
 {
  id: 9,
  range: "70 ~ 79",
 },
 {
  id: 10,
  range: "80 ~ 89",
 },
 {
  id: 11,
  range: "90 ~ 99",
 },
];
