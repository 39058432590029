import { F_INPUTERROR, F_PAGEINFO, F_SYSTEMNOTI } from "./common";
import { gql } from "@apollo/client";

export const SYSTEMNOTI_LIST = gql`
 query systemNotiList(
  $sort: [_SystemNotiSort!]
  $filter: _SystemNotiFilter
  $pagingInput: OffsetPagingInput!
 ) {
  SystemNotiList(sort: $sort, pagingInput: $pagingInput, filter: $filter) {
   pageInfo {
    ...FoffsetPagingInfo
   }
   items {
    ...FsystemNoti
   }
  }
 }
 ${F_PAGEINFO}
 ${F_SYSTEMNOTI}
`;

export const UNREAD_NOTIES = gql`
 query unReadNoties {
  UnReadNoties
 }
`;

export const SYSTEMNOTI_READ = gql`
 mutation systemNotiRead($ids: [String!]!) {
  SystemNotiRead(ids: $ids) {
   ok
   error {
    ...FinputError
   }
  }
 }
 ${F_INPUTERROR}
`;

export const SYSTEMNOTI_HIDE = gql`
 mutation systemNotiHide($ids: [String!]!) {
  SystemNotiHide(ids: $ids) {
   ok
   error {
    ...FinputError
   }
  }
 }
 ${F_INPUTERROR}
`;

export const SYSTEMNOTI_CREATE = gql`
 mutation SystemNotiCreate(
  $message: String!
  $userIds: [String!]
  $serverity: SystemNotiSeverity
 ) {
  SystemNotiCreate(
   message: $message
   userIds: $userIds
   serverity: $serverity
  ) {
   ok
   error {
    ...FinputError
   }
  }
 }
 ${F_INPUTERROR}
`;
