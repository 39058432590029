import React, { useRef, useState } from "react";
import { TDIV } from "../data/type";
import { useMultiUpload } from "../hook/useMiltiUpload";
import { useSingleUpload } from "../hook/useSingleUpload";
import { encodeImageToBlurhash } from "../utils/blurhash";
import { resizeImage } from "../utils/file/resizeImage";
import { isImgFile } from "../utils/validate";
import { FfileFragment } from "../app/apollo/type/graphql";

interface IProp extends Omit<TDIV, "onProgress"> {
 onUploaded?: (
  file: FfileFragment,
  { blurHash }: { blurHash: string; file: File }
 ) => void;
 onUploadeds?: (files: FfileFragment[]) => void;
 isVideo?: boolean;
 resize?: {
  width: number;
 };
 multiple?: boolean;
 setLoading: React.Dispatch<React.SetStateAction<boolean>>;
 onProgress?: (progress: string) => void;
 accept?: "image/*" | "video/*" | "audio/*";
 maxFileLength?: number;
 gallery?: boolean;
}

export const ImageDropzone: React.FC<IProp> = ({
 onUploaded,
 onUploadeds,
 multiple,
 isVideo,
 children,
 resize,
 onProgress,
 gallery,
 maxFileLength,
 setLoading,
 accept = "image/*",
 ...props
}) => {
 const multiUploadHook = useMultiUpload(undefined, gallery);
 const singleUploadHook = useSingleUpload();
 const dropzoneRef = useRef<HTMLDivElement | null>(null);
 const fileInputRef = useRef<HTMLInputElement | null>(null);

 const onDropzoneClick = () => {
  fileInputRef.current?.click();
 };

 const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
  event.preventDefault();
  event.currentTarget.style.backgroundColor = "#e0e0e0";
 };

 const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
  event.currentTarget.style.backgroundColor = "";
 };

 const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
  event.preventDefault();
  event.currentTarget.style.backgroundColor = "";

  const files = event.dataTransfer.files;
  handleFiles(files);
 };

 const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  if (event.target.files) {
   handleFiles(event.target.files);
  }
 };

 const handleFiles = async (files: FileList) => {
  if (maxFileLength && files.length > (maxFileLength || 0)) {
   alert(`최대 ${maxFileLength}개의 파일을 업로드 할 수 있습니다.`);
   return;
  }
  if (files.length > 0) {
   setLoading(true);
   let arrayFiles = Array.from(files);

   const resizedFiles = await Promise.all(
    arrayFiles.map(async (file) => {
     if (resize?.width && isImgFile(file)) {
      onProgress?.("사이즈 조정중");
      const resized = await resizeImage({
       file: file,
       maxSize: (resize?.width || 1000) * 3,
      });
      return resized;
     }
     return file;
    })
   );

   try {
    if (!multiple) {
     const file = resizedFiles[0];
     onProgress?.("파일 업로드중");
     const result = await singleUploadHook.fileUpload(file);
     if (result) {
      onProgress?.("로딩 이미지 제작중");
      const blurHash = await encodeImageToBlurhash(result.uri!);
      onUploaded?.(result, {
       file,
       blurHash: blurHash as string,
      });
     } else {
      alert("업로드실패");
     }
    } else {
     const result = await multiUploadHook.fileUploads(resizedFiles);
     if (result) {
      onProgress?.("로딩 이미지 제작중");
      onUploadeds?.(result);
     } else {
      alert("업로드실패");
     }
    }
   } catch (error) {
    console.error(error);
   } finally {
    setLoading(false);
   }
  }
 };

 return (
  <div
   ref={dropzoneRef}
   onClick={onDropzoneClick}
   onDragOver={onDragOver}
   onDragLeave={onDragLeave}
   onDrop={onDrop}
   {...props}
  >
   <input
    type="file"
    multiple={multiple}
    ref={fileInputRef}
    onChange={onFileInputChange}
    style={{ display: "none" }}
    accept={accept}
   />
   {children}
  </div>
 );
};
