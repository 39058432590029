import { gql } from "@apollo/client";
import { F_FILE } from "./file";
import { F_INPUTERROR } from "./common";

export const F_COUPON = gql`
 fragment Fcoupon on Coupon {
  _id
  title
  subText
  photo {
   ...Ffile
  }
  used
  itemId
 }
 ${F_FILE}
`;

export const COUPON_USE = gql`
 mutation couponUse($couponId: String!, $itemId: String!) {
  CouponUse(couponId: $couponId, itemId: $itemId) {
   ok
   error {
    ...FinputError
   }
   data {
    ...Fcoupon
   }
  }
 }
 ${F_COUPON}
 ${F_INPUTERROR}
`;
