import { gql } from "@apollo/client";
import {
 F_COLLECTION_DATA_INTERFACE,
 F_INPUTERROR,
 F_PAGEINFO,
} from "./common";
import { F_FILE } from "./file";
import { F_DETAIL_LOCATIOn } from "./location";
import { F_COUPON } from "./coupon";
import { F_BOUCHER } from "./boucher";

export const F_ITEM_DETAIL = gql`
 fragment FitemDetail on Item {
  ...FcollectionDataInterface
  boothId
  image {
   ...Ffile
  }
  type
  name
  categoryKey
  contact
  information
  address
  coupon {
   ...Fcoupon
  }
  addressDetail {
   ...FdetailLocation
  }
  bouchers {
   ...Fboucher
  }
  shorts {
   ...Ffile
  }
  lat
  lng
 }
 ${F_BOUCHER}
 ${F_COUPON}
 ${F_DETAIL_LOCATIOn}
 ${F_FILE}
 ${F_COLLECTION_DATA_INTERFACE}
`;

export const F_ITEM = gql`
 fragment Fitem on Item {
  ...FcollectionDataInterface
  image {
   ...Ffile
  }
  name
 }
 ${F_FILE}
 ${F_COLLECTION_DATA_INTERFACE}
`;

export const ITEM_UPDATE = gql`
 mutation itemUpdate($input: ItemInput!, $itemId: String!) {
  ItemUpdate(itemId: $itemId, input: $input) {
   ok
   error {
    ...FinputError
   }
  }
 }
 ${F_INPUTERROR}
`;

export const ITEM_CREATE = gql`
 mutation itemCreate($input: ItemInput!) {
  ItemCreate(input: $input) {
   ok
   error {
    ...FinputError
   }
  }
 }
 ${F_INPUTERROR}
`;

export const ITEM_DELETE = gql`
 mutation itemDelete($itemId: String!) {
  ItemDelete(itemId: $itemId) {
   ok
   error {
    ...FinputError
   }
  }
 }
 ${F_INPUTERROR}
`;

export const ITEM_FIND_BY_ID = gql`
 query itemFindById($itemId: String!, $selectAll: Boolean!) {
  ItemFindById(itemId: $itemId, selectAll: $selectAll) {
   ...FitemDetail
  }
 }
 ${F_ITEM_DETAIL}
`;

export const ITEM_FIND_BY_PATH = gql`
 query itemFindByPath($path: String!) {
  ItemFindByPath(path: $path) {
   ...Fitem
  }
 }
 ${F_ITEM}
`;

export const ITEM_FIND_BY_STORE_ID = gql`
 query itemFindByStoreId($storeId: String!) {
  ItemFindByStoreId(storeId: $storeId) {
   ...Fitem
  }
 }
 ${F_ITEM}
`;

export const ITEM_LIST = gql`
 query itemList(
  $sort: [_ItemSort!]
  $filter: _ItemFilter
  $pagingInput: OffsetPagingInput!
 ) {
  ItemList(sort: $sort, pagingInput: $pagingInput, filter: $filter) {
   pageInfo {
    ...FoffsetPagingInfo
   }
   items {
    ...Fitem
   }
  }
 }
 ${F_FILE}
 ${F_ITEM}
 ${F_PAGEINFO}
`;

export const F_ITEM_MAP = gql`
 fragment FitemMap on ItemMap {
  _id
  name
  boothId
  categoryKey
  lat
  lng
 }
`;
export const ITEM_MAP = gql`
 query itemMap($sort: [_ItemSort!], $filter: _ItemFilter) {
  ItemMap(sort: $sort, filter: $filter) {
   ...FitemMap
  }
 }
 ${F_ITEM_MAP}
`;
