import { useLocation, Outlet } from "react-router-dom";
import Footer from "./components/footer/Footer";
import { useAppContext } from "../hook/useApp.context";

export default function Layout() {
 const { updateKey } = useAppContext();
 const loc = useLocation();

 console.log("updateKey", updateKey);

 const visit = loc.pathname.includes("visit");
 return (
  <div className="flex flex-col w-full max-w-[768px] mx-auto h-auto bg-neutral-100">
   <Outlet />
   {!visit && <Footer />}
  </div>
 );
}
