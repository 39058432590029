import { BellIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { useUnReadNotiesQuery } from "../../app/apollo/type/graphql";
import { twMerge } from "tailwind-merge";
import { SystemNotiPopup } from "./NotificationPop";
import { useState } from "react";
import { useAppContext } from "../../hook/useApp.context";
import { Photo } from "../../components/Photo";

interface IProp {}

export const NotificationBell = () => {
 const { user } = useAppContext();
 const count = user?.unReadSystemNoties?.length;

 const [open, setOpen] = useState(false);
 return (
  <>
   <div className="w-[6.6vw] h-[6.6vw] md:w-12 md:h-12 flex items-center justify-center relative">
    <div
     className={twMerge(
      classNames(
       "w-[2.7vw] h-[2.7vw] md:h-5 md:w-5 bg-amber-400 rounded-full absolute flex items-center justify-center top-0 right-0",
       {
        ["opacity-0"]: count === 0,
       }
      )
     )}
    >
     <div className="relative h-full w-full flex items-center justify-center">
      <p className="text-white text-[1.6vw] md:text-xs font-bold leading-none absolute ">
       {count || ""}
      </p>
     </div>
    </div>
    <Photo
     width={36}
     height={36}
     src={"https://jw.stayjanda.cloud/keyband/bell_alarm.svg"}
     alt={"mainbellicon"}
     className="text-white w-[5vw] h-[5vw] md:w-9 md:h-9 cursor-pointer"
     onClick={() => setOpen(true)}
    />
   </div>
   {open && (
    <SystemNotiPopup
     unReadNotiIds={user?.unReadSystemNoties || []}
     onClose={() => {
      setOpen(false);
     }}
    />
   )}
  </>
 );
};
