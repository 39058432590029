import { XMarkIcon } from "@heroicons/react/24/solid";
import { useEffect } from "react";
import {
 Category,
 _SystemNotiSort,
 useSystemNotiReadMutation,
} from "../../app/apollo/type/graphql";
import { Photo } from "../../components/Photo";
import { L } from "../../data/lang.static";
import { useAppContext } from "../../hook/useApp.context";
import { systemNotiListHook } from "../../hook/useSystemNoti";
import { getRefetch } from "../../utils/getRefetch";
import { UNREAD_NOTIES } from "../../apollo/gql/systemNoti";
import { notiContentGen } from "../../utils/noti";
import { yyyymmddHHmm } from "../../utils/date";
import { Airplan, CategoryImgMap } from "../../data/const";
import { USER_CREATE } from "../../apollo/gql/user";

interface IProp {
 onRead?: () => void;
 onClose?: () => void;
 unReadNotiIds: string[];
}

export const SystemNotiPopup: React.FC<IProp> = ({
 onClose,
 onRead,
 unReadNotiIds,
}) => {
 const context = useAppContext();
 const [read] = useSystemNotiReadMutation({
  ...getRefetch(USER_CREATE, UNREAD_NOTIES),
 });

 const { items: systemNoteis, getLoading } = systemNotiListHook(
  {
   initialSort: [_SystemNotiSort.CreatedAtDesc],
  },
  {
   fetchPolicy: "cache-and-network",
  }
 );

 useEffect(() => {
  read({
   variables: {
    ids: unReadNotiIds,
   },
  });
 }, []);

 if (getLoading) return null;

 return (
  <div className="  absolute w-full bg-white h-auto py-[2.7vw] pl-[4.2vw] pr-[2.7vw] md:py-5 md:px-[30px] top-[9.7vw] md:top-[70px] md:rounded-[20px] rounded-[2.7vw] z-40 shadow-[0_0px_10px_rgba(0,0,0,0.1)]">
   <div className="w-full flex items-center justify-between h-[8.8vw] md:h-16">
    <h2 className="text-neutral-800 text-[4.4vw] md:text-[32px] font-semibold">
     {L["Notifications"]}
    </h2>
    <Photo
     src={"https://jw.stayjanda.cloud/keyband/X_button.svg"}
     className="h-[8.8vw] w-[8.8vw] md:w-20 md:h-20 text-neutral-400"
     onClick={() => onClose?.()}
    />
   </div>
   <div className="max-h-[60vh] overflow-auto">
    {systemNoteis.map((noti, index) => {
     const isNew = unReadNotiIds.includes(noti._id);

     return (
      <div
       key={noti._id + "SystemNoti"}
       className="mt-[2.7vw] md:mt-5 py-[1.5vw] md:py-[10px] gap-x-[2.7vw] md:gap-x-5 flex items-center"
      >
       <div className=" max-w-fit  min-w-[6.6vw] md:min-w-12 w-[6.6vw] h-[6.6vw] md:w-12 md:h-12 relative">
        {isNew && (
         <div className="absolute   w-[1.1vw] h-[1.1vw] md:w-2 md:h-2 rounded-full bg-indigo-500"></div>
        )}
        {/* airplane_icon.svg */}
        <Photo
         className="h-full w-auto"
         src={
          index === 0
           ? CategoryImgMap[noti.info?.itemCategory as Category] || Airplan
           : CategoryImgMap.Attractions
         }
         alt={"profile"}
        />
       </div>
       <div className="flex flex-col gap-y-[0.8vw]">
        <p className="text-neutral-700 text-[3.9vw] md:text-[28px] font-semibold break-all">
         {notiContentGen(noti, context)}
        </p>
        <p className="text-neutral-700 text-[3.3vw] md:text-2xl font-normal leading-none">
         {yyyymmddHHmm(noti.createdAt)}
        </p>
       </div>
      </div>
     );
    })}
   </div>
  </div>
 );
};
