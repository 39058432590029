import GoogleMapReact from "google-maps-react-markers";
import { useAppContext } from "../hook/useApp.context";
import { useState } from "react";

type Mark = {
 lat: number;
 lon: number;
 name?: string;
};

interface IProp extends Partial<React.ComponentProps<typeof GoogleMapReact>> {}

export const KakaoMap: React.FC<IProp> = ({ children, ...mapProps }) => {
 const [currentZoom, setCurrentZoom] = useState(mapProps.defaultZoom || 0);
 const { langCode } = useAppContext();
 // const [loading, error] = useKakaoLoader({
 //     appkey: process.env.REACT_APP_API_KAKAO_MAP_KEY!,
 //     libraries: ["clusterer", "drawing", "services"]
 // });

 const defaultProps = {
  center: {
   lat: 36.2683,
   lng: 127.6358,
  },
 };

 // var geocoder = new kakao.maps.services.Geocoder();
 // geocoder.addressSearch("서울특별시 강남구 봉은사로 524", function(result) {
 //     const lat = result[0]?.y;
 //     const lng = result[0]?.x;
 // })

 return (
  <GoogleMapReact
   {...defaultProps}
   apiKey="AIzaSyDMtojL_SM6qXdjoasu-xR4Ns_jpI2R2l4"
   externalApiParams={{
    language: langCode,
   }}
   options={{
    mapTypeControl: false,
    panControl: false,
    fullscreenControl: false,
    rotateControl: false,
    scaleControl: false,
    zoomControl: false,
    clickableIcons: false,
    fullscreenControlOptions: false,
   }}
   onChange={({ zoom }) => {
    setCurrentZoom(zoom);
   }}
   defaultZoom={currentZoom}
   defaultCenter={{
    lng: 129.0689,
    lat: 35.21,
   }}
   {...mapProps}
  >
   {currentZoom < 11 ? null : children}
  </GoogleMapReact>
 );
};

// const MapRefresher: React.FC<IProp> = props => {
//     const { container, getByName, preview } = useContext(ContainerContext);
//     const element = getByName<JWDom.Map>(props.name);
//     const eleOrDefault = element || DEFAULT_LAT_LNG;
//     return (
//         <Map
//             key={container.id + eleOrDefault.lat + eleOrDefault.lng}
//             {...props}
//         />
//     );
// };
