export const CountryData = [
 { id: 1, name: "Select Country", code: "None" },
 { id: 2, name: "United States", code: "US" },
 { id: 3, name: "China", code: "CN" },
 { id: 4, name: "India", code: "IN" },
 { id: 5, name: "United Kingdom", code: "GB" },
 { id: 6, name: "Germany", code: "DE" },
 { id: 7, name: "France", code: "FR" },
 { id: 8, name: "Japan", code: "JP" },
 { id: 9, name: "Canada", code: "CA" },
 { id: 10, name: "Australia", code: "AU" },
 { id: 11, name: "Brazil", code: "BR" },
 { id: 12, name: "Russia", code: "RU" },
 { id: 13, name: "South Korea", code: "KR" },
 { id: 14, name: "Spain", code: "ES" },
 { id: 15, name: "Italy", code: "IT" },
 { id: 16, name: "Mexico", code: "MX" },
 { id: 17, name: "Indonesia", code: "ID" },
 { id: 18, name: "Netherlands", code: "NL" },
 { id: 19, name: "Saudi Arabia", code: "SA" },
 { id: 20, name: "Turkey", code: "TR" },
 { id: 21, name: "Switzerland", code: "CH" },
 { id: 22, name: "Sweden", code: "SE" },
 { id: 23, name: "Belgium", code: "BE" },
 { id: 24, name: "Poland", code: "PL" },
 { id: 25, name: "Norway", code: "NO" },
 { id: 26, name: "Austria", code: "AT" },
 { id: 27, name: "Ireland", code: "IE" },
 { id: 28, name: "Denmark", code: "DK" },
 { id: 29, name: "Singapore", code: "SG" },
 { id: 30, name: "South Africa", code: "ZA" },
 { id: 31, name: "New Zealand", code: "NZ" },
 { id: 32, name: "Thailand", code: "TH" },
 { id: 33, name: "Argentina", code: "AR" },
 { id: 34, name: "Chile", code: "CL" },
 { id: 35, name: "Colombia", code: "CO" },
 { id: 36, name: "UAE", code: "AE" },
 { id: 37, name: "Egypt", code: "EG" },
 { id: 38, name: "Nigeria", code: "NG" },
 { id: 39, name: "Kenya", code: "KE" },
 { id: 40, name: "Vietnam", code: "VN" },
 { id: 41, name: "Malaysia", code: "MY" },
 { id: 42, name: "Philippines", code: "PH" },
 { id: 43, name: "Ukraine", code: "UA" },
 { id: 44, name: "Portugal", code: "PT" },
 { id: 45, name: "Czech Republic", code: "CZ" },
 { id: 46, name: "Greece", code: "GR" },
 { id: 47, name: "Israel", code: "IL" },
 { id: 48, name: "Hungary", code: "HU" },
 { id: 49, name: "Romania", code: "RO" },
 { id: 50, name: "Bangladesh", code: "BD" },
 { id: 51, name: "Pakistan", code: "PK" },
 { id: 52, name: "Iran", code: "IR" },
 { id: 53, name: "Iraq", code: "IQ" },
 { id: 54, name: "Peru", code: "PE" },
 { id: 55, name: "Venezuela", code: "VE" },
 { id: 56, name: "Morocco", code: "MA" },
 { id: 57, name: "Algeria", code: "DZ" },
 { id: 58, name: "Ghana", code: "GH" },
 { id: 59, name: "Uzbekistan", code: "UZ" },
 { id: 60, name: "Kazakhstan", code: "KZ" },
 { id: 61, name: "Sri Lanka", code: "LK" },
 { id: 62, name: "Ethiopia", code: "ET" },
 { id: 63, name: "Sudan", code: "SD" },
 { id: 64, name: "Angola", code: "AO" },
 { id: 65, name: "Dominican Republic", code: "DO" },
 { id: 66, name: "Mongolia", code: "MN" },
 { id: 67, name: "Jamaica", code: "JM" },
 { id: 68, name: "Oman", code: "OM" },
 { id: 69, name: "Latvia", code: "LV" },
 { id: 70, name: "Finland", code: "FI" },
 { id: 71, name: "Norway", code: "NO" },
 { id: 72, name: "Sweden", code: "SE" },
 { id: 73, name: "Denmark", code: "DK" },
 { id: 74, name: "Iceland", code: "IS" },
 { id: 75, name: "Bolivia", code: "BO" },
 { id: 76, name: "Panama", code: "PA" },
 { id: 77, name: "Costa Rica", code: "CR" },
 { id: 78, name: "Trinidad and Tobago", code: "TT" },
 { id: 79, name: "Uruguay", code: "UY" },
 { id: 80, name: "Paraguay", code: "PY" },
 { id: 81, name: "Other Country", code: "OUTLIST" },
].sort((a, b) => {
 const nameA = a.name.toUpperCase(); // Convert to uppercase to ensure case-insensitive comparison
 const nameB = b.name.toUpperCase(); // Convert to uppercase to ensure case-insensitive comparison

 if (nameA < nameB) {
  return -1; // nameA comes first
 }
 if (nameA > nameB) {
  return 1; // nameB comes first
 }
 return 0; // names are equal
});
