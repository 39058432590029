import {
 authLink,
 cleanMutationField,
 errorLink,
 fileUploadLink,
} from "./links";
import { ApolloClient, InMemoryCache, from } from "@apollo/client";
import { SERVER_URI } from "../uri";
import cache from "./cache";
import "react-toastify/dist/ReactToastify.css";

export const apolloClient = new ApolloClient({
 link: from([cleanMutationField, errorLink, authLink, fileUploadLink]),
 uri: SERVER_URI + "/graphql",
 cache,
});
