import { FitemFragment } from "../../app/apollo/type/graphql";
import { Photo } from "../../components/Photo";
import { CheckBox, UnCheckBox } from "../../data/Icon";
import { Paths } from "../../data/const";
import { useAppContext } from "../../hook/useApp.context";
import { useNavigate } from "react-router-dom";

interface IPlaceProp
 extends React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
 > {
 item: FitemFragment;
}

export const Place: React.FC<IPlaceProp> = ({ item, ...props }) => {
 const { langCode, user, lang } = useAppContext();
 const checked = user?.Visits?.some((v) => v.itemId === item._id);
 const Icon = checked ? CheckBox : UnCheckBox;
 const navigate = useNavigate();

 const goDetail = () => {
  navigate(Paths["/place"] + "?itemId=" + item._id);
 };

 return (
  <div
   onClick={goDetail}
   //h-[55vw] md:h-[400px]
   className="w-full min-h-[43vw] md:min-h-44 rounded-[4.2vw] md:rounded-[30px] relative"
   {...props}
  >
   <Photo
    size="500"
    className="w-full h-full object-cover rounded-[4.2vw] md:rounded-[30px] z-0"
    file={item.image?.[0]}
   />
   <div className="absolute w-full h-full bg-black bg-opacity-30 top-0 left-0 rounded-[4.2vw] md:rounded-[30px] z-10" />
   <Icon className="z-10 absolute md:w-12 md:h-12 w-[6.6vw] h-[6.6vw] right-[2.2vw] md:right-5 bottom-[2.2vw] md:bottom-5" />

   <p className="top-[2.5vw] md:top-4  leading-[1.3em] left-0 md:px-4 px-[2.5vw] absolute text-white text-[4.4vw] md:text-[32px] font-bold z-20">
    {lang(item.name)}
   </p>
  </div>
 );
};

export const PlacePlaceHolder = () => {
 return <div className="w-full h-1"></div>;
};
