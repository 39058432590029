import dataURLtoFile from "./dataURLtoFile";
import { fileExtendDivider } from "./fileExtendDivider";
import { IResizeImageOptions } from "./fileResize";
import { resizeCanvasImg } from "./resizeCanvas";

const getOptimizeCanvas = (width: number, height: number) => {
 try {
  const offCanvas = new OffscreenCanvas(width, height);
  return offCanvas;
 } catch {
  const canvas = document.createElement("canvas");
  return canvas;
 }
};

export const resizeImage = (settings: IResizeImageOptions): Promise<File> => {
 const file = settings.file;
 const maxSize = settings.maxSize;
 const reader = new FileReader();
 const image = new Image();

 const resize = async () => {
  //4
  let width = image.width;
  let height = image.height;

  if (width > height) {
   if (width > maxSize) {
    height *= maxSize / width;
    width = maxSize;
   }
  } else {
   if (height > maxSize) {
    width *= maxSize / height;
    height = maxSize;
   }
  }

  const canvas = getOptimizeCanvas(width, height);

  const canvasContext = canvas.getContext("2d");
  canvas.width = width;
  canvas.height = height;
  canvasContext?.drawImage(image, 0, 0, width, height);

  console.log("!!!", {
   width,
   height,
  });
  resizeCanvasImg(canvas, width, height, true);

  const fileName = settings.changedFileName || file.name;
  const { extend, namePart } = fileExtendDivider(fileName);
  const suffixStr = `---${settings.suffix || maxSize}`;
  const newfileName = namePart + suffixStr + "." + extend;

  if (canvas instanceof HTMLCanvasElement) {
   const dataUrl = canvas.toDataURL(file.type);
   return dataURLtoFile(dataUrl, newfileName);
  } else {
   const blob = await canvas.convertToBlob();
   return new File([blob], newfileName);
  }
 };

 return new Promise((ok, no) => {
  if (!file.type.match(/image.*/)) {
   no(false);
   return;
  }

  reader.onload = async (readerEvent: any) => {
   image.onload = async () => {
    const file = await resize();
    ok(file);
   }; //3
   image.src = readerEvent.target.result; // 2
  };
  reader.readAsDataURL(file); // 1
 });
};
