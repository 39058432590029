import React, { useCallback, useContext, useRef, useState } from "react";
import Footer from "./components/footer/Footer";
import { twMerge } from "tailwind-merge";
import classNames from "classnames";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Photo } from "../components/Photo";
import { KakaoMap } from "../components/Map";
import {
 Category,
 FitemMapFragment,
 useItemFindByIdQuery,
 useItemMapQuery,
} from "../app/apollo/type/graphql";
import { Transition } from "@headlessui/react";
import { CustomMap } from "./components/CustomMap";
import { MapPin } from "../components/MapPin";
import { CategoryColor, Paths } from "../data/const";
import { useAppContext } from "../hook/useApp.context";
import { CategoryBadge } from "../components/CategoryBadge";
import { useNavigate } from "react-router-dom";
import { SkipUpdate } from "../components/SkipUpdate";

const imgLoadMap = new window.Map();

export const Map: React.FC = () => {
 const [selectPin, setSelectPin] = useState<FitemMapFragment | null>(null);
 const [pinImageLoaded, setPinImageLoaded] = useState(false);
 const [mode, setMode] = useState<"customMap" | "map">("customMap");
 const isMapMode = mode === "map";
 const { lang } = useAppContext();
 const navigate = useNavigate();
 const [opacity, setOpacity] = useState(1);

 const { data: item } = useItemFindByIdQuery({
  variables: {
   selectAll: false,
   itemId: selectPin?._id!,
  },
  skip: !selectPin,
 });

 const { data: itemMap } = useItemMapQuery({});
 const itemData = item?.ItemFindById;
 const categoryKey = itemData?.categoryKey;
 const markers = itemMap?.ItemMap;

 const handlePin = (mk: FitemMapFragment) => () => {
  setSelectPin(mk);
 };

 console.log("@@@@@@!!@!@markers", markers);

 const imgUrl = itemData?.image?.[0]?.uri;

 return (
  <div className="max-w-[768px] mx-auto w-full h-screen overflow-hidden">
   <div className="flex flex-col w-full h-screen relative items-center justify-end">
    <div className="absolute flex justify-center top-[11vw] md:top-20 mx-auto z-10 p-[0.8vw] md:p-1.5 bg-white rounded-full">
     <div
      className={twMerge(
       classNames(
        "w-[22.2vw] md:w-40 md:h-14 py-[1.9vw] px-[3.6vw] md:py-[14px] md:px-[26px] bg-white rounded-[30px] flex justify-center items-center",
        {
         ["bg-indigo-500"]: mode === "customMap",
        }
       )
      )}
      onClick={() => {
       setMode("customMap");
      }}
     >
      <p
       className={twMerge(
        classNames(
         "text-indigo-500 text-[3.9vw] md:text-[28px] font-bold text-center",
         {
          ["text-white"]: mode === "customMap",
         }
        )
       )}
      >
       Bexco
      </p>
     </div>
     <div
      className={twMerge(
       classNames(
        "w-[22.2vw] md:w-40 md:h-14 py-[1.9vw] px-[3.6vw] md:py-[14px] md:px-[26px] bg-white  rounded-[30px] flex justify-center items-center",
        {
         ["bg-indigo-500"]: mode === "map",
        }
       )
      )}
      onClick={() => {
       setMode("map");
      }}
     >
      <p
       className={twMerge(
        classNames(
         "text-[3.9vw] md:text-[28px] font-bold text-center text-indigo-500",
         {
          ["text-white"]: mode === "map",
         }
        )
       )}
      >
       Busan
      </p>
     </div>
    </div>
    <div className="w-full h-screen flex flex-col relative items-center justify-center">
     {!isMapMode ? (
      <TransformWrapper
       initialScale={2}
       minScale={1}
       maxScale={10}
       centerOnInit
       wheel={{
        step: 0.2,
        smoothStep: 0.01,
        activationKeys: [],
        excluded: [],
       }}
       pinch={{
        excluded: [],
        step: 50,
       }}
       panning={{
        activationKeys: [],
        excluded: [],
       }}
       doubleClick={{
        excluded: [],
        step: 0.7,
       }}
       onInit={function noRefCheck() {}}
       onPanning={function noRefCheck() {}}
       onPanningStart={function noRefCheck() {}}
       onPanningStop={function noRefCheck() {}}
       onPinching={function noRefCheck() {}}
       onPinchingStart={function noRefCheck() {}}
       onPinchingStop={function noRefCheck() {}}
       onTransformed={function noRefCheck() {}}
       onWheel={function noRefCheck() {}}
       onWheelStart={function noRefCheck() {}}
       onWheelStop={function noRefCheck() {}}
       onZoom={function noRefCheck() {}}
       onZoomStart={function noRefCheck() {}}
       onZoomStop={function noRefCheck() {}}
      >
       <TransformComponent wrapperClass="!overflow-visible">
        <CustomMap
         items={itemMap?.ItemMap || ([] as any)}
         onSelect={(item) => {
          setSelectPin(item);
          //   setPinImageLoaded(false);
         }}
         selectedBoothId={item ? "booth-N" : ""}
         //selectPin?.boothId
        />
       </TransformComponent>
      </TransformWrapper>
     ) : (
      <KakaoMap
       defaultZoom={12}
       style={{
        width: "100%",
        height: "100%",
       }}
       defaultCenter={{
        lat: selectPin?.lat || 35.1002169222157,
        lng: selectPin?.lng || 129.032488274285,
       }}
      >
       {markers?.map((mk) => {
        return (
         <MapPin
          style={{
           opacity,
          }}
          key={mk.lat + mk.lng + "mark"}
          onClick={handlePin(mk)}
          lat={mk.lat}
          lng={mk.lng}
          color={CategoryColor[(mk.categoryKey as Category) || "Cafe"] as any}
         />
        );
       })}
      </KakaoMap>
     )}
    </div>
    <Photo
     key={imgUrl}
     className="hidden"
     src={imgUrl}
     onLoad={() => {
      imgLoadMap.set(imgUrl, true);
      //   setPinImageLoaded(true);
     }}
    />
    <Transition
     appear
     show={!!itemData}
     enter="transition  w-full duration-[1s] ease-out"
     enterFrom="transform  translate-y-1 opacity-0"
     enterTo="transform translate-y-0  opacity-100"
     leave="transition  w-full duration-1000 ease-out"
     leaveFrom="transform opacity-100"
     leaveTo="transform  opacity-0"
    >
     <div
      onClick={() => {
       navigate(Paths["/place"] + "?itemId=" + itemData?._id);
      }}
      className="z-50 flex flex-col h-auto left-0 right-0 mx-auto fixed bottom-0 max-w-[768px] p-[5.5vw] md:p-[40px]"
     >
      <div className="flex items-start  w-full md:p-[30px] p-[4.2vw] z-10 gap-x-[5.5vw] md:gap-x-5 bg-white rounded-[4.2vw] md:rounded-[30px] shadow">
       <div className="min-w-[25vw] h-[25vw] md:min-w-[180px] md:h-[180px] rounded-[2.7vw] md:rounded-[20px]">
        {itemData && (
         <Photo
          width={180}
          height={180}
          className="object-cover w-full h-full "
          file={itemData?.image?.[0]}
          size={200}
          onLoad={() => {
           imgLoadMap.set(imgUrl, true);
           setPinImageLoaded(true);
          }}
          alt={"placePhoto"}
         />
        )}
       </div>
       <div className="flex flex-col h-full items-start">
        {itemData?.categoryKey && (
         <CategoryBadge categoryKey={itemData?.categoryKey!} />
        )}
        <h2 className="text-neutral-700 text-[4.4vw] md:text-[32px] font-semibold md:leading-9 mt-[2.7vw] md:mt-5 leading-tight">
         {lang(itemData?.name)}
        </h2>
        <p className="line-clamp-2 text-netural-700 text-[3.3vw] md:text-[24px] mt-[1.1vw] md:mt-2.5 font-normal">
         {lang(itemData?.information)}
        </p>
       </div>
      </div>
      <div className="opacity-0 bottom-0 w-full max-w-[768px] z-[9999] bg-white grid grid-cols-4 items-center py-2.5 md:py-5 border-t border-neutral-200">
       <div className="flex flex-col items-center">
        <Photo
         width={20}
         height={20}
         className="w-[6.6vw] h-[6.6vw] md:w-10 md:h-10"
         src={"https://jw.stayjanda.cloud/keyband/home_select_icon.svg"}
         alt={""}
        />
        <p className="text-[2.7vw] md:text-[22px] font-semibold text-indigo-500 mt-1">
         HOME
        </p>
       </div>
      </div>
     </div>
    </Transition>
   </div>
  </div>
 );
};

export default Map;
