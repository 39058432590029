import { ITEM_LIST } from "../apollo/gql/item";
import { generateListQueryHook } from "../apollo/query";
import {
 FitemFragment,
 ItemListQuery,
 ItemListQueryVariables,
 _ItemFilter,
 _ItemSort,
} from "../app/apollo/type/graphql";

export const itemListHook = generateListQueryHook<
 _ItemFilter,
 _ItemSort,
 ItemListQuery,
 ItemListQueryVariables,
 FitemFragment
>(ITEM_LIST);
