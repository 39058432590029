import { SYSTEMNOTI_LIST } from "../apollo/gql/systemNoti";
import { generateListQueryHook } from "../apollo/query";
import {
 FsystemNotiFragment,
 SystemNotiListQuery,
 SystemNotiListQueryVariables,
 _ItemFilter,
 _ItemSort,
 _SystemNotiFilter,
 _SystemNotiSort,
} from "../app/apollo/type/graphql";

export const systemNotiListHook = generateListQueryHook<
 _SystemNotiFilter,
 _SystemNotiSort,
 SystemNotiListQuery,
 SystemNotiListQueryVariables,
 FsystemNotiFragment
>(SYSTEMNOTI_LIST);
