import classNames from "classnames";
import { twMerge } from "tailwind-merge";

export interface IProp extends React.HTMLAttributes<HTMLButtonElement> {
 variant?: "flat" | "shallow" | "border" | "text";
 color?: "primary-dark" | "primary" | "red" | "grey" | "neutral";
 size?: "S" | "L";
 disabled?: boolean;
 selected?: boolean;
 type?: string;
}

export const Button: React.FC<IProp> = ({
 selected,
 color = selected ? "primary" : "grey",
 variant = "flat",
 size,
 disabled,
 className,
 ...props
}) => {
 color = selected ? "primary" : color;
 const buttonClasses = twMerge(
  classNames(
   "cursor-pointer rounded w-full text-neutral-600 text-sm flex items-center justify-center h-11   focus:outline-none transition-colors duration-200 ease-in-out",
   {
    "px-3 h-8 text-xs": size === "S",
    "px-4 h-[52px] font-semibold text-base": size === "L",
   },
   {
    "bg-indigo-600 hover:bg-indigo-500 text-white":
     color === "primary" && variant === "flat",
    "bg-blue-700 hover:bg-blue-800 text-white":
     color === "primary-dark" && variant === "flat",
    "bg-red-500 hover:bg-red-600 text-white":
     color === "red" && variant === "flat",
    "bg-neutral-800 hover:bg-neutral-700 text-white":
     color === "neutral" && variant === "flat",
    "text-neutral-400 !bg-neutral-200 cursor-auto":
     disabled && variant === "flat",
    "text-neutral-600  bg-neutral-200 hover:bg-neutral-300":
     color === "grey" && variant === "flat",
   },
   {
    "bg-white  hover:bg-neutral-50 text-neutral-800 border border-neutral-300":
     color === "neutral" && variant === "border",
    "bg-white hover:bg-indigo-50 text-indigo-600 border border-indigo-600":
     color === "primary" && variant === "border",
    "bg-white bg-blue-50 hover:bg-blue-100 text-indigo-600 border border-blue-500":
     color === "primary-dark" && variant === "border",
    "bg-white  hover:bg-red-50 text-red-500 border border-red-500":
     color === "red" && variant === "border",
    "!bg-white text-neutral-400 border border-neutral-400 cursor-auto":
     disabled && variant === "border",
   },
   {
    " bg-neutral-50 text-neutral-800 hover:bg-neutral-100":
     color === "neutral" && variant === "shallow",
    "bg-blue-50 text-blue-500 hover:bg-blue-100":
     color === "primary" && variant === "shallow",
    "bg-blue-100 text-blue-500 hover:bg-blue-50":
     color === "primary-dark" && variant === "shallow",
    "bg-red-50 text-red-500 hover:bg-red-100":
     color === "red" && variant === "shallow",
    " text-neutral-400 !bg-neutral-200 cursor-auto":
     disabled && variant === "shallow",
   },
   {
    "text-neutral-800 hover:text-neutral-600":
     color === "neutral" && variant === "text",
    "text-blue-500 hover:text-blue-600":
     color === "primary" && variant === "text",
    "text-blue-600 hover:text-blue-700":
     color === "primary-dark" && variant === "text",
    "text-red-500 hover:text-red-600": color === "red" && variant === "text",
    "!bg-white !text-neutral-400 cursor-auto": disabled && variant === "text",
   },
   className
  )
 );
 return (
  <button disabled={disabled} className={buttonClasses} {...(props as any)} />
 );
};
