import { Photo } from "../components/Photo";
import LineProgressBar from "../components/LineProgressBar";
import React, { Suspense, useEffect } from "react";
import { useAppContext } from "../hook/useApp.context";
import { L } from "../data/lang.static";
import { itemListHook } from "../hook/useItemList";
import { useQrCodeScanner } from "../hook/useQrCodeScanner";
import { useVisitCreateMutation } from "../app/apollo/type/graphql";
import { getRefetch } from "../utils/getRefetch";
import { USER_CREATE, USER_FIND_BY_NFC_ID } from "../apollo/gql/user";
import { useNavigate } from "react-router-dom";
import { Paths } from "../data/const";
const QrCodeScanner = React.lazy(() => import("../components/QrcodeScanner"));

export const Albums = () => {
 const { user } = useAppContext();
 const navigate = useNavigate();

 const maxAlbum = 30;

 const [visitMu] = useVisitCreateMutation({
  awaitRefetchQueries: true,
  ...getRefetch(USER_CREATE),
 });

 const qrCodeScannerHook = useQrCodeScanner({
  onCaptureData(qrData) {
   try {
    console.log("After valid url");

    const itemId = new URL(qrData).searchParams.get("itemId");

    console.log("itemId", itemId);
    if (itemId) {
     //  getUserLocation().then(({ latitude, longitude }: any) => {
     closeCamera();
     visitMu({
      variables: {
       input: {
        nfcId: user?.nfcId!,
        itemId,
        userId: user?._id!,
        //  lat: latitude as number,
        //  lng: longitude as number,
       },
      },
     }).then(({ data }) => {
      if (data?.VisitCreate.ok) navigate(Paths["/visit"] + "/" + itemId);
      else {
       if (data?.VisitCreate.error?.code === "1001")
        alert(L["already_set_visit"]);
      }
     });
     //  });
    }
   } catch (e) {
    console.log("qrCode data is not valid Url");
   }
  },
 });
 const { initCamera, closeCamera } = qrCodeScannerHook;
 const numbers = Array.from({ length: maxAlbum }, (_, index) => index + 1);
 const visitItemIds = user?.Visits?.map((visit) => visit.itemId);
 const { items, refetch } = itemListHook({
  initialFilter: {
   _id__in: visitItemIds as string[],
  },
 });

 useEffect(() => {
  refetch({
   filter: {
    _id__in: (visitItemIds as string[]) || [],
   },
  });
 }, [visitItemIds?.length]);

 const visitLen = visitItemIds?.length || 0;
 return (
  <>
   <div className="w-full h-auto flex flex-col items-center justify-center bg-indigo-500 rounded-b-[9.7vw] md:rounded-b-[70px] px-[8.3vw] md:px-[60px] pt-[11.1vw] md:pt-20 pb-[9.7vw] md:pb-[70px]">
    <p className="text-[5.5vw] md:text-[40px] font-semibold text-white md:leading-10 text-center leading-none">
     {L["PHOTO_ALBUMS"]}
    </p>
    <p className="text-[3.8vw] md:text-[28px] font-normal mt-[1.5vw] md:mt-2.5 text-white md:leading-7  text-center">
     {L["album_subtitle"]}
    </p>
    <div className="flex w-auto justify-center gap-x-[1.5vw] md:gap-x-2.5 text-white text-[3.3vw] md:text-2xl font-semibold mt-[5.5vw] md:mt-10">
     <p>{L["Tourist_Point_label"]}</p>
     <p>
      {visitLen} {L["a_Of_b"]} {maxAlbum}
     </p>
    </div>
    <div className="p-[0.8vw] md:p-[6px] w-full bg-white mt-[4.2vw] md:mt-[30px] rounded-full">
     <LineProgressBar
      percent={(visitLen * 100) / maxAlbum}
      rounded={36}
      height={36}
      progressColor="#FBBF24"
      containerColor="#FFFFFF"
      className="w-full !h-[1.5vw] md:!h-3 bg-white rounded-full"
     />
     <div></div>
    </div>
    <div className="flex w-full justify-between mt-2.5">
     <p className="text-white text-[2.7vw] md:text-xl font-normal">1</p>
     <p className="text-white text-[2.7vw] md:text-xl font-normal">30</p>
    </div>
   </div>
   <div className="w-full grid grid-cols-5 mt-[8.3vw] md:mt-[60px] px-[5.5vw] md:px-10 mx-auto justify-between gap-[11px] md:gap-y-[23px] mb-[152px] md:mb-[303px]">
    {numbers.map((number, index) => {
     const item = items[index];

     return (
      <div
       key={number}
       onClick={() => {
        navigate(Paths["/place"] + "?itemId=" + item._id);
       }}
       className="w-[15.2vw] h-[15.2vw] md:w-[110px] md:h-[110px] rounded-[4.1vw] md:rounded-[30px] bg-white relative mx-auto"
      >
       {item && (
        <Photo
         size={100}
         src={item?.image?.[0]?.uri}
         alt={""}
         className="rounded-[4.1vw] md:rounded-[30px] w-full h-full object-cover"
        />
       )}
      </div>
     );
    })}
   </div>

   <div
    onClick={() => {
     initCamera();
    }}
    className="fixed max-w-[768px] w-full bottom-10 bg-black flex justify-end"
   >
    <div
     className={
      "w-[14vw] h-[14vw] md:w-[104px] md:h-[104px] flex items-center justify-center rounded-full bg-amber-400 absolute bottom-[13.8vw] right-[5.5vw] md:right-10 md:bottom-[150px] shadow-[0_0px_10px_rgba(0,0,0,0.1)]"
     }
    >
     <Photo
      width={32}
      height={32}
      className="w-[8.8vw] h-[8.8vw] md:w-16 md:h-16"
      src={"https://jw.stayjanda.cloud/keyband/camera_icon_white.svg"}
      alt={"camerabutton"}
     />
    </div>
   </div>

   <Suspense>
    <QrCodeScanner {...qrCodeScannerHook} />
   </Suspense>
  </>
 );
};

export default Albums;
