import classNames from "classnames";
import { Category } from "../app/apollo/type/graphql";
import { L } from "../data/lang.static";
import { Photo } from "./Photo";
import { Airplan, CategoryColor, CategoryImgMap } from "../data/const";

interface IProp {
 categoryKey: Category;
}

export const CategoryBadge: React.FC<IProp> = ({ categoryKey }) => {
 return (
  <div
   className={classNames(
    "KBbadge flex items-center gap-x-[0.5vw] md:gap-x-2.5 py-[0.8vw] md:py-[6px] px-[1.5vw] md:px-2.5  rounded-[0.8vw] md:rounded-md",
    {
     "bg-teal-400": categoryKey === Category.Attractions,
     "bg-red-400": categoryKey === Category.Cafe,
     "bg-amber-400": categoryKey === Category.Convention,
    }
   )}
  >
   <Photo
    width={20}
    height={20}
    className="md:w-5 md:h-5 brightness-[500] w-[2.7vw] h-[2.7vw]"
    src={CategoryImgMap[categoryKey] || Airplan}
    alt={"아이콘"}
   />
   <p className="text-white text-[2.5vw] md:text-lg font-medium leading-3">
    {L[categoryKey]}
   </p>
  </div>
 );
};
