import React, { ReactNode } from "react";
import { useEffect, useLayoutEffect, useState } from "react";
import useUpdateEffect from "../hook/useUpdateEffect";

interface IWithLoadingProp {
 loading?: boolean;
 Loading?: JSX.Element;
 onlyOnce?: boolean;
 loadingTrail?: number;
 skipInitTrail?: boolean;
 loadingChecker?: () => boolean;
 loadingCheckRepeatTime?: number;
 invisibleLoadingStrategy?: boolean;
 children?: ReactNode;
}

export const WithLoading: React.FC<IWithLoadingProp> = ({
 Loading = null,
 loading,
 children,
 loadingTrail,
 onlyOnce,
 skipInitTrail,
 loadingChecker,
 invisibleLoadingStrategy,
 loadingCheckRepeatTime = 200,
}) => {
 const [loadedOnce, setLoadedOnce] = useState(false);
 const [selfLoading, setSelfLoading] = useState<boolean>(
  loadingChecker ? true : false
 );
 const [timer, setTimer] = useState<NodeJS.Timer | null>(null);
 const [trailling, setTrailling] = useState(!skipInitTrail && !!loadingTrail);

 useLayoutEffect(() => {
  if (loading && timer) {
   setTrailling(true);
   clearTimeout(timer as any);
  }
  if (loadingTrail && !loading) {
   setTimer(
    setTimeout(() => {
     setTrailling(false);
    }, loadingTrail)
   );
  }
 }, [loading]);

 const selfLoadingCheck = (): NodeJS.Timeout | void => {
  if (!loadingChecker) return;

  const checkAgain = () => {
   setTimeout(selfLoadingCheck, loadingCheckRepeatTime);
  };
  const isLoading = loadingChecker();
  if (isLoading || trailling) {
   return checkAgain();
  }
  setSelfLoading(false);
  return;
 };

 const selfLoadingCheckStart = () => {
  const timer = selfLoadingCheck();
  return () => {
   if (timer) clearTimeout(timer);
  };
 };

 useEffect(selfLoadingCheckStart, [trailling]);

 const anyLoading = selfLoading || loading || trailling;
 useUpdateEffect(() => {
  if (!loading && onlyOnce && !anyLoading && !loadedOnce) {
   setLoadedOnce(true);
  }
 }, [loading, anyLoading]);

 if (invisibleLoadingStrategy) {
  return (
   <>
    {anyLoading && Loading}
    <div style={{ visibility: "hidden", height: 0, overflow: "hidden" }}>
     {children}
    </div>
   </>
  );
 }

 if (onlyOnce && loadedOnce) return children;
 if (selfLoading) return Loading;
 if (loading || trailling) return Loading;
 return children || (null as any);
};
