import { ReactElement, ReactNode, useContext } from "react";
import { AppContext, useAppContext } from "../../hook/useApp.context";
import { Navigate } from "react-router-dom";

interface IProp {
 children: ReactElement;
}

export const ProtectedRoute: React.FC<IProp> = ({ children }) => {
 const { user } = useAppContext(); // Use context

 if (!user?.signed) {
  // Redirect to login page if not authenticated
  return <Navigate to="/signup" replace />;
 }

 return children;
};
