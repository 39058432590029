import { getRefetch } from "./../utils/getRefetch";
import { useState } from "react";
import {
 FfileFragment,
 useFileUploadsMutation,
} from "../app/apollo/type/graphql";
import { FILE_LIST } from "../apollo/gql/file";

export type TUseSingleUpload = ReturnType<typeof useMultiUpload>;
export const useMultiUpload = (
 defaultFiles?: FfileFragment[],
 gallery?: boolean
) => {
 const [fileUploading, setLoading] = useState(false);
 const [files, setFiles] = useState<FfileFragment[] | undefined>(defaultFiles);
 const [uploadMu] = useFileUploadsMutation({
  ...getRefetch(FILE_LIST),
 });

 const fileUploads = async (files: File[]) => {
  setLoading(true);

  let allFiles: File[] = files;
  let tags: {
   key: string;
   value: string;
  }[];

  const fileUploads = allFiles.map((file) => ({
   upload: file,
   tags,
  }));

  return uploadMu({
   variables: {
    files: fileUploads,
    gallery: true,
   },
  })
   .then(({ data }) => {
    const file = data?.FileUploads.data;
    if (file) {
     setFiles(file);
    }
    return file;
   })
   .finally(() => {
    setLoading(false);
   });
 };

 const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  const file = e.currentTarget.files;
  if (!file) return;
  return await fileUploads(Array.from(file));
 };

 const onChangeFile = async (files: File[]) => {
  return await fileUploads(files);
 };

 return {
  fileUploads,
  fileUploading,
  onChange,
  onChangeFile,
  files,
  setFiles,
 };
};
