import { gql } from "@apollo/client";

export const F_DETAIL_LOCATIOn = gql`
 fragment FdetailLocation on DetailLocation {
  lng
  lat
  addressDetail
  zonecode
  address
  addressEnglish
  addressType
  userSelectedType
  noSelected
  userLanguageType
  roadAddress
  roadAddressEnglish
  jibunAddress
  jibunAddressEnglish
  autoRoadAddress
  autoRoadAddressEnglish
  autoJibunAddress
  autoJibunAddressEnglish
  buildingCode
  buildingName
  apartment
  sido
  sidoEnglish
  sigungu
  sigunguEnglish
  sigunguCode
  roadnameCode
  bcode
  roadname
  roadnameEnglish
  bname
  bnameEnglish
  bname1
  bname1English
  bname2
  bname2English
  hname
  query
  postcode
  postcode1
  postcode2
  postcodeSeq
 }
`;
