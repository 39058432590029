import React from "react";

interface LineProgressBarProps {
 percent: number;
 rounded: number;
 height: number;
 progressColor: string;
 containerColor: string;
 className?: string;
}

const LineProgressBar: React.FC<LineProgressBarProps> = ({
 percent,
 rounded,
 height,
 progressColor,
 containerColor,
 className = "",
}) => {
 const containerStyles = {
  backgroundColor: containerColor,
  borderRadius: `${rounded}px`,
  height: `${height}px`,
 };

 const fillerStyles = {
  height: "100%",
  width: `${percent}%`,
  backgroundColor: progressColor,
  borderRadius: "inherit",
  textAlign: "right",
  transition: "width 0.3s ease-in-out",
 };

 return (
  <div className={`w-full ${className}`} style={containerStyles}>
   <div style={fillerStyles as any} />
  </div>
 );
};

export default LineProgressBar;
