import { gql } from "@apollo/client";
import {
 F_COLLECTION_DATA_INTERFACE,
 F_INPUTERROR,
 F_PAGEINFO,
} from "./common";

export const F_FILE = gql`
 fragment Ffile on File {
  ...FcollectionDataInterface
  name
  description
  extension
  fileType
  uri
  blur
  tags {
   key
   value
  }
 }
 ${F_COLLECTION_DATA_INTERFACE}
`;

export const FILE_UPLOADS = gql`
 mutation fileUploads($files: [FileInput!]!, $gallery: Boolean) {
  FileUploads(files: $files, gallery: $gallery) {
   ok
   error {
    ...FinputError
   }
   data {
    ...Ffile
   }
  }
 }
 ${F_FILE}
 ${F_INPUTERROR}
`;

export const FILE_LIST = gql`
 query fileList(
  $sort: [_FileSort!]
  $filter: _FileFilter
  $pagingInput: OffsetPagingInput!
 ) {
  FileList(sort: $sort, pagingInput: $pagingInput, filter: $filter) {
   pageInfo {
    ...FoffsetPagingInfo
   }
   items {
    ...Ffile
   }
  }
 }
 ${F_PAGEINFO}
 ${F_FILE}
`;

export const FILE_DELETES = gql`
 mutation fileDeletes($deleteFileList: [ObjectId!]!) {
  FileDeletes(deleteFileList: $deleteFileList) {
   ok
   error {
    ...FinputError
   }
  }
 }
 ${F_INPUTERROR}
`;
