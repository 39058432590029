import { useState, useRef, useEffect } from "react";
import jsQR from "jsqr";

type Prop = {
 onCaptureData: (data: string) => void;
};
export type TUseQrCodeScanner = ReturnType<typeof useQrCodeScanner>;
export const useQrCodeScanner = ({ onCaptureData }: Prop) => {
 const [open, setOpen] = useState(false);
 const videoRef = useRef<HTMLVideoElement | null>(null);
 const canvasRef = useRef<HTMLCanvasElement | null>(null);

 const initCamera = async () => {
  try {
   const constraints: MediaStreamConstraints = {
    video: { facingMode: "environment" },
   };
   const stream = await navigator.mediaDevices.getUserMedia(constraints);
   if (videoRef.current) videoRef.current.srcObject = stream;
   scanQRCode();
   setOpen(true);
  } catch (err) {
   alert("카메라에 접근할 수 없습니다.");
   console.error("Error accessing the camera: ", err);
  }
 };

 const scanQRCode = () => {
  if (!videoRef.current || !canvasRef.current) return;

  const video = videoRef.current;
  const canvas = canvasRef.current;
  const context = canvas.getContext("2d");
  if (!context) return;

  if (video.readyState === video.HAVE_ENOUGH_DATA) {
   canvas.height = video.videoHeight;
   canvas.width = video.videoWidth;
   context.drawImage(video, 0, 0, canvas.width, canvas.height);

   const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
   const qrCode = jsQR(imageData.data, imageData.width, imageData.height, {
    inversionAttempts: "attemptBoth",
   });

   if (qrCode) {
    try {
     console.log("After valid url");

     const itemId = new URL(qrCode.data).searchParams.get("itemId");

     if (itemId) {
      onCaptureData(qrCode.data);
      setOpen(false);
      cleanCamera();
     }
    } catch (e) {
     console.log("qrCode data is not valid Url", qrCode.data);
    }
    // Handle QR code detection (display data, trigger action, etc.)
   }
  }
  requestAnimationFrame(scanQRCode);
 };

 const cleanCamera = () => {
  if (videoRef.current && videoRef.current.srcObject) {
   (videoRef.current.srcObject as MediaStream)
    .getTracks()
    .forEach((track) => track.stop());
  }
 };

 const closeCamera = () => {
  setOpen(false);
  cleanCamera();
 };
 useEffect(() => {
  return cleanCamera;
 }, []);

 return {
  closeCamera,
  cleanCamera,
  videoRef,
  canvasRef,
  open,
  setOpen,
  initCamera,
 };
};
