import { useState, useEffect } from "react";

const useScrollBeyondScreen = (callback: () => void) => {
 const [touchStartY, setTouchStartY] = useState(0);

 useEffect(() => {
  const handleTouchStart = (e: TouchEvent) => {
   // 터치 시작 위치 저장
   setTouchStartY(e.touches[0].clientY);
  };

  const handleTouchEnd = (e: TouchEvent) => {
   const touchEndY = e.changedTouches[0].clientY;
   const touchMoveDistance = touchStartY - touchEndY; // 움직인 거리 계산 (아래로 드래그하면 양수 값)

   // 남은 스크롤 가능한 높이 계산
   const remainingScrollHeight =
    document.documentElement.scrollHeight - window.innerHeight - window.scrollY;

   // 움직인 거리가 남은 스크롤 높이보다 클 경우 콜백 함수 호출
   if (touchMoveDistance > 0 && touchMoveDistance > remainingScrollHeight) {
    callback();
   }
  };

  // 이벤트 리스너 등록
  window.addEventListener("touchstart", handleTouchStart);
  window.addEventListener("touchend", handleTouchEnd);

  // 컴포넌트가 언마운트 되거나 업데이트될 때 이벤트 리스너 제거
  return () => {
   window.removeEventListener("touchstart", handleTouchStart);
   window.removeEventListener("touchend", handleTouchEnd);
  };
 }, [callback, touchStartY]);

 return null; // 필요한 경우 여기에서 상태를 반환할 수 있습니다.
};

export default useScrollBeyondScreen;
