import { encode } from "blurhash";

const loadImage = async (src: string): Promise<HTMLImageElement> =>
 new Promise((resolve, reject) => {
  const img = new Image();
  img.setAttribute("crossOrigin", "");
  img.onload = () => resolve(img);
  img.onerror = (...args) => reject(args);
  img.src = src;
 });

const getImageData = (image: HTMLImageElement) => {
 const canvas = document.createElement("canvas");
 canvas.width = image.width;
 canvas.height = image.height;
 const context = canvas.getContext("2d") as any;
 context.drawImage(image, 0, 0);
 return context.getImageData(0, 0, image.width, image.height);
};

export const encodeImageToBlurhash = async (imageUrl: string) => {
 let image = await loadImage(imageUrl).catch((e) => {
  console.error("image load error", e);
  return null;
 });
 if (!image) return undefined;
 const imageData = getImageData(image);
 return encode(imageData.data, imageData.width, imageData.height, 4, 4);
};

export const FileToBlurhash = async (file: File) => {
 return new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = async (e) => {
   let image = new Image();
   image.onload = () => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = image.width;
    canvas.height = image.height;
    ctx?.drawImage(image, 0, 0);
    const imageData = ctx?.getImageData(0, 0, canvas.width, canvas.height);
    if (imageData) {
     const blurhash = encode(
      imageData.data,
      imageData.width,
      imageData.height,
      4,
      4
     );
     resolve(blurhash);
    } else {
     reject(new Error("Could not get image data"));
    }
   };
   image.onerror = (e) => {
    console.error("image load error", e);
    reject(e);
   };
   if (e.target?.result) {
    image.src = e.target.result.toString();
   }
  };
  reader.readAsDataURL(file);
 });
};
