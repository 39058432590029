import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";
import fetch from "cross-fetch";
import { getOperationName } from "@apollo/client/utilities";
import { ApolloLink, NextLink, Operation, split } from "@apollo/client";
import { omits } from "../utils/omits";
import { removeUndefindOfObject } from "../utils/removeOfObject";
import { FILE_UPLOADS } from "./gql/file";
import { SERVER_URI } from "../uri";
import { getLangCode } from "../data/lang.static";
import { LocalStorage } from "../utils/localstorage/StorageAddition";

const doNotRetryOperation = [getOperationName(FILE_UPLOADS)];

export const retryLink = new RetryLink({
 delay: {
  initial: 2000,
  max: 5000,
 },
 attempts: {
  max: 3,
  retryIf: (error, _operation) => {
   const clientFault = error?.statusCode === 400;
   return (
    !clientFault && !doNotRetryOperation.includes(_operation.operationName)
   );
  },
 },
});

export const errorLink = onError(
 ({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
   graphQLErrors.forEach((graphqlError) => {
    const { message, locations, path } = graphqlError;
    console.warn(
     `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
    );
    // if (process.env.NODE_ENV === "development") {
    //     toast.warn(message);
    // }
   });

   // logSend({
   //     level: "error",
   //     input: operation.variables,
   //     message: graphQLErrors,
   //     about: `graphql error encounter [${operation.operationName}]`,
   // });
  } else if (networkError) {
   if (operation.operationName.includes("Subscribe")) return forward(operation);
   console.log("networkError", networkError);
   // logSend({
   //     about: "network error encounter",
   //     level: "error",
   //     input: networkError,
   // });
   // const serverNotRespondCnt =
   //     SessionStroage.getNum("serverNotRespondCnt") || 0;
   // if (window.navigator.onLine) {
   //     SessionStroage.set(
   //         "serverNotRespondCnt",
   //         serverNotRespondCnt + 1
   //     );

   //     if (!noServerErrorMessage && serverNotRespondCnt > 5)
   //         toast.warn("서버가 응답하지 않습니다.", {
   //             toastId: "ServerIsNotRespond",
   //         });
   // } else {
   //     toast.warn("네트워크 연결 상태를 확인 해주세요.", {
   //         toastId: "ServerIsNotRespond",
   //     });
   // }
  } else {
   // SessionStroage.removeItem("serverNotRespondCnt");
  }
 }
);

export const cleanMutationField = (operation: Operation, forward: NextLink) => {
 const definition = operation?.query?.definitions.filter(
  (def) => def.kind === "OperationDefinition"
 )?.[0];
 const mutation = "mutation";
 if (
  definition?.kind == "OperationDefinition" &&
  definition?.operation === mutation
 ) {
  operation.variables = omits(operation.variables);
  return forward(operation);
 }
 return forward(operation);
};

// export const getMachineId = () => {
//     let machineId = LocalStorage.getItem(LSK.machineId) || "";
//     if (!machineId) {
//         machineId = s8();
//         LocalStorage.setItem(LSK.machineId, machineId);
//     }
//     return machineId;
// };

export const authLink = setContext((_, meta) => {
 if (!meta) return {};

 console.log("!!@!@", LocalStorage.getItem("id"));

 console.log(
  "!!!getLangCode(navigator.language)",
  getLangCode(navigator.language)
 );

 return {
  headers: {
   ...meta.headers,
   nfcid: LocalStorage.getItem("id"),
   language: getLangCode(LocalStorage.getItem("lang") || navigator.language),
   "apollo-require-preflight": "true",
  },
 };
});

export const fileUploadLink = createUploadLink({
 uri: SERVER_URI + "/graphql",
 credentials: "include",
 fetch,
});

export const afterwareLink = new ApolloLink((operation, forward) => {
 const fowareds = forward(operation);
 if (!fowareds.map) return fowareds;
 return fowareds.map((response) => {
  const context = operation.getContext();
  const {
   response: { headers, body, status },
  } = context;

  if (headers) {
   // if (headers.get("Refresh")) {
   //     logSend({
   //         level: "info",
   //         message: { refresh: headers.get("Refresh"), version },
   //         about: "reload with cache",
   //     });
   //     const newUrl = updateURLParameter(
   //         location.href,
   //         "ver",
   //         new Date().valueOf().toString()
   //     );
   //     location.href = newUrl;
   // }
   const bookerSession = headers.get("bookerSession");
   // const approachStoreOwnerId = SessionFisrtLocalStorage.getItem(
   //     LSK.approachStoreOwnerId
   // );
   if (bookerSession) {
    localStorage.setItem("bsk", bookerSession);
   }
  }

  return response;
 });
});
