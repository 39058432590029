import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Photo } from "../components/Photo";

import { useAppContext } from "../hook/useApp.context";
import { L } from "../data/lang.static";
import { Category, FitemFragment } from "../app/apollo/type/graphql";
import { itemListHook } from "../hook/useItemList";
import { whenEnter } from "../utils/event";
import { Place, PlacePlaceHolder } from "./components/Place";
import { NotificationBell } from "./components/NotificationBell";
import { WithLoading } from "../components/WithLoading";
import { Spinner } from "../components/Spinner";
import { SkipUpdate } from "../components/SkipUpdate";
import { Transition } from "@headlessui/react";
import useUserDragScreen from "../hook/useWindowScrollEnded";
import { useNavigate } from "react-router-dom";
import { Paths } from "../data/const";
import { useState } from "react";
import { WithEmpty } from "../components/WithEmpty";
import { Empty } from "./components/Empty";
import { isEmpty } from "../utils/isEmpty";
import { hiGen } from "../utils/noti";
import { getFromUrl } from "../utils/getFromUrl";

export const MainPage = () => {
 const context = useAppContext();
 const { user } = context;
 const [count, setCount] = useState<number>(20);
 const [openPop, setOpenPop] = useState<boolean>(false);
 const [isInputClicked, setIsInputClicked] = useState(false);
 const navigate = useNavigate();

 const { items, setFilter, setViewCount, viewCount, getLoading } = itemListHook(
  {
   initialViewCount: 12,
  }
 );

 const [search, setSearch] = useState("");
 useUserDragScreen(() => {
  setViewCount(viewCount + 1);
 });

 const handleSearch = (cat?: Category) => {
  const param = new URLSearchParams({
   search: search,
   ...(cat ? { cat } : undefined),
  });
  const searchStr = param.toString();
  navigate(Paths["/search"] + "?" + searchStr);
 };

 const lefts: FitemFragment[] = [];
 const rights: FitemFragment[] = [];

 items.forEach((item, index) => {
  const left = index % 2 === 0;
  if (left) {
   lefts.push(item);
  } else {
   rights.push(item);
  }
 });

 const plusCount = () => {
  setCount(count + 1);
 };
 const minusCount = () => {
  if (count <= 0) {
   return;
  } else {
   setCount(count - 1);
  }
 };
 const handlePopup = () => {
  setOpenPop(!openPop);
 };
 return (
  <div className="h-auto max-w-[768px] mx-auto w-full flex flex-col min-h-screen bg-neutral-100">
   <div className="w-full h-auto bg-indigo-500 rounded-b-[10vw] md:rounded-b-[70px] py-[11vw] md:py-20 px-[5.5vw] md:px-10">
    <div className="flex justify-between w-full items-center relative">
     <h2
      className="text-white text-[min(5.5vw,36px)] md:text-[40px] font-semibold"
      onClick={() => plusCount()}
     >
      {hiGen(context)}
     </h2>
     <NotificationBell />
    </div>
    <p
     className="text-white text-[min(3.8vw,24px)] md:text-[28px] font-normal mt-[5px] md:mt-2.5"
     onClick={() => minusCount()}
    >
     {L["MainPageTopText"]}
    </p>
    <div className="flex px-[2.7vw] py-[1.5vw] md:px-5 md:py-2.5 w-fit bg-indigo-300 rounded-[4.2vw] md:rounded-[30px] justify-center items-center gap-[5px] mt-[2.7vw] md:mt-5">
     <p className="text-white text-[2.7vw] md:text-lg font-semibold">
      {L["Tourist_Point_label"]} :
     </p>
     <p className="text-indigo-500 text-[2.7vw] md:text-lg font-semibold">
      {user?.Visits?.length || 0}
     </p>
    </div>
    <div className="w-full relative">
     <div className="absolute rounded-[2.7vw] md:rounded-[20px] h-[12.2vw] md:h-[84px] w-full bg-white flex justify-between items-center mt-[6vw] md:mt-11 p-[2.7vw] px-[4.2vw] md:p-5">
      <input
       onKeyDown={whenEnter((e) => {
        handleSearch();
       })}
       value={search}
       onChange={(e) => {
        setSearch(e.currentTarget.value);
       }}
       className="text-neutral-400 text-[3.9vw] md:text-[28px] font-normal w-full outline-none"
       placeholder={isInputClicked ? "" : L["SearchBarPlaceHolder"]}
       onFocus={() => {
        setIsInputClicked(true);
       }}
       onBlur={() => {
        setIsInputClicked(false);
       }}
      />
      {search && (
       <XMarkIcon
        onClick={() => {
         setSearch("");
        }}
        className="w-[6.6vw] h-[6.6vw] md:w-12 md:h-12 text-neutral-400"
       />
      )}
      <MagnifyingGlassIcon
       onClick={() => {
        handleSearch();
       }}
       className="w-[6.6vw] h-[6.6vw] md:w-12 md:h-12 text-neutral-400"
      />
     </div>
    </div>
   </div>
   <div className="mt-[12.2vw] md:mt-[88px] px-[5.5vw] md:px-10">
    <div className="flex justify-between">
     <div
      onClick={() => {
       handleSearch(Category.Convention);
      }}
      className="flex flex-col items-center w-[27.7vw] md:w-[200px] h-auto md:h-[148px] bg-white rounded-[2.7vw] md:rounded-[20px] justify-center py-[2.7vw] md:py-10 md:px-[60px]"
     >
      <Photo
       width={60}
       height={60}
       className="w-[8.8vw] md:w-[64px] md:h-[64px]"
       src={"https://jw.stayjanda.cloud/keyband/convention_Icon.svg"}
       alt={"convention"}
      />
      <p className="whitespace-nowrap mt-[2.7vw] md:mt-5 text-neutral-700 text-[3.3vw] md:text-2xl font-semibold ">
       {L["Convention"]}
      </p>
     </div>
     <div
      onClick={() => {
       handleSearch(Category.Cafe);
      }}
      className="flex flex-col items-center w-[27.7vw] md:w-[200px] h-auto md:h-[148px] bg-white rounded-[2.7vw] md:rounded-[20px] justify-center py-[2.7vw] md:py-10 md:px-[60px]"
     >
      <Photo
       width={60}
       height={60}
       className="w-[8.8vw] md:w-[64px] md:h-[64px]"
       src={"https://jw.stayjanda.cloud/keyband/cafe_Icon.svg"}
       alt={"convention"}
      />
      <p className=" whitespace-nowrap mt-[2.7vw] md:mt-5 text-neutral-700 text-[3.3vw] md:text-2xl font-semibold">
       {L["Cafe"]}
      </p>
     </div>
     <div
      onClick={() => {
       handleSearch(Category.Attractions);
      }}
      className="flex flex-col items-center w-[27.7vw] md:w-[200px] h-auto md:h-[148px] bg-white rounded-[2.7vw] md:rounded-[20px] justify-center py-[2.7vw] md:py-10 md:px-[60px]"
     >
      <Photo
       width={60}
       height={60}
       className="w-[8.8vw] md:w-[64px] md:h-[64px]"
       src={"https://jw.stayjanda.cloud/keyband/attractions_Icon.svg"}
       alt={"convention"}
      />
      <p className="whitespace-nowrap mt-[2.7vw] md:mt-5 text-neutral-700 text-[3.3vw] md:text-2xl font-semibold">
       {L["Attractions"]}
      </p>
     </div>
    </div>
    <div className="mt-[5.5vw] md:mt-10 w-full h-auto">
     <div className="flex h-[8.8vw] md:h-16 items-center justify-between">
      <p className="text-neutral-700 text-[3.9vw] md:text-[28px] font-bold">
       {L["PlacesTitle"]}
      </p>
      <p
       onClick={() => {
        navigate(Paths["/search"]);
       }}
       className="text-indigo-500 text-[3.9vw] md:text-[28px] font-semibold underline"
      >
       {L["See_All"]}
      </p>
     </div>
     <div className="mt-[2.7vw] md:mt-5 mb-[28vw] md:mb-[200px] flex justify-center gap-x-[5.5vw] md:gap-x-[40px] ">
      <WithLoading onlyOnce loading={getLoading}>
       <SkipUpdate skip={getLoading}>
        <WithEmpty
         Empty={<Empty text={L["noResultFoundText"]} />}
         empty={isEmpty(items) && false}
        >
         <div className="w-full flex flex-col gap-y-[5.5vw] md:gap-y-10">
          {lefts.map((item) => {
           return <Place key={item._id + "Place"} item={item} />;
          })}
          <PlacePlaceHolder />
         </div>
         <div className="w-full flex flex-col gap-y-[5.5vw] md:gap-y-10">
          {rights.map((item) => {
           return <Place key={item._id + "Place"} item={item} />;
          })}
          <PlacePlaceHolder />
         </div>
        </WithEmpty>
       </SkipUpdate>
      </WithLoading>
     </div>
     <WithLoading
      loadingTrail={1000}
      Loading={
       <Transition
        appear
        show={getLoading}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-1000 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
       >
        <div className="fixed left-1/2  bottom-[15vw] mx-auto w-[5vw]">
         <Spinner />
        </div>
       </Transition>
      }
      loading={getLoading}
     ></WithLoading>

     {/* {scrolling && <Spinner />} */}
    </div>
   </div>
  </div>
 );
};

export default MainPage;
