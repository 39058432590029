import { DocumentMagnifyingGlassIcon } from "@heroicons/react/24/outline";

interface IProp {
 text: string;
}

export const Empty: React.FC<IProp> = ({ text }) => {
 return (
  <div className="w-full h-[40vw] p-2.5 rounded-lg border border-neutral-200 justify-center items-center inline-flex">
   <div className="flex-col flex text-center">
    <DocumentMagnifyingGlassIcon className="text-neutral-400 mx-auto mb-[3vw] w-[8vw] h-auto" />
    <div className=" text-neutral-400 text-[4vw] font-normal leading-none">
     {text}
    </div>
   </div>
  </div>
 );
};
