import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { Photo } from "../components/Photo";
import { Paths } from "../data/const";
import { useAppContext } from "../hook/useApp.context";
import {
 Category,
 FitemFragment,
 _ItemFilter,
 useItemListQuery,
} from "../app/apollo/type/graphql";
import { itemListHook } from "../hook/useItemList";
import { whenEnter } from "../utils/event";
import { Place, PlacePlaceHolder } from "./components/Place";
import { getFromUrl } from "../utils/getFromUrl";
import { WithEmpty } from "../components/WithEmpty";
import { Empty } from "./components/Empty";
import { SkipUpdate } from "../components/SkipUpdate";
import { L } from "../data/lang.static";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../components/Spinner";
import { WithLoading } from "../components/WithLoading";
import { Transition } from "@headlessui/react";

export const SearchPage = () => {
 const { user, langSearch } = useAppContext();
 const [count, setCount] = useState<number>(20);
 const [openPop, setOpenPop] = useState<boolean>(false);
 const [isInputClicked, setIsInputClicked] = useState(false);
 const urlSearch = decodeURIComponent(getFromUrl("search") || "");
 const [search, setSearch] = useState(urlSearch);
 const cat = getFromUrl("cat");
 const navigate = useNavigate();

 const orSearch = langSearch([
  {
   address_ko__contains: search,
  },
  {
   name_ko__contains: search,
  },
  {
   information_ko__contains: search,
  },
 ] as _ItemFilter[]);

 const catQuery = cat ? { categoryKey__eq: cat as Category } : {};

 const ORquery = search ? { OR: orSearch } : {};

 const searchFilter = {
  ...ORquery,
  ...catQuery,
 };

 const { items, setFilter, getLoading } = itemListHook({
  initialFilter: searchFilter,
 });

 const lefts: FitemFragment[] = [];
 const rights: FitemFragment[] = [];

 items.forEach((item, index) => {
  const left = index % 2 === 0;
  if (left) {
   lefts.push(item);
  } else {
   rights.push(item);
  }
 });

 return (
  <div className="w-full max-w-[768px] mx-auto pt-[11.1vw] md:pt-20 h-screen flex flex-col min-h-screen bg-neutral-100 px-[5.5vw] md:px-10">
   <div className="flex relative w-full justify-center items-center my-[1.6vw] md:my-3">
    <Photo
     onClick={() => {
      navigate(Paths["/"]);
     }}
     className="w-[8.8vw] h-[8.8vw] md:w-16 md:h-16 absolute left-0"
     src={"https://jw.stayjanda.cloud/keyband/back_Icon.svg"}
     alt={"back"}
    />
    <p className="text-neutral-800 text-[5.5vw] md:text-[40px] font-bold">
     {cat ? L[cat as "Nickname"] : "Search"}
    </p>
   </div>
   <div className="bg-white mt-[5.5vw] md:mt-10 w-full p-[2.7vw] pl-[4.2vw] md:p-5 md:pl-[30px] rounded-[2.7vw] md:rounded-[20px] flex justify-between">
    <input
     value={search}
     onChange={(e) => {
      setSearch(e.currentTarget.value);
     }}
     className="text-neutral-400 outline-none text-[3.9vw] md:text-[28px] font-normal leading-3 w-full "
     placeholder={isInputClicked ? "" : L["SearchBarPlaceHolder"]}
    />
    {search && (
     <XMarkIcon
      onClick={() => {
       setSearch("");
       setFilter({
        ...catQuery,
       });
      }}
      className="w-[6.6vw] h-[6.6vw] md:w-12 md:h-12 text-neutral-400"
     />
    )}
    <MagnifyingGlassIcon
     onClick={() => {
      setFilter(searchFilter);
     }}
     className="w-[6.6vw] h-[6.6vw] md:w-12 md:h-12"
    />
   </div>
   <div className="mt-[5.5vw] md:mt-10 w-full h-auto">
    <div className=" mb-[28vw] md:mb-[200px] flex justify-center gap-x-[5.5vw] md:gap-x-[40px] ">
     <WithLoading onlyOnce loading={getLoading}>
      <SkipUpdate skip={getLoading}>
       <WithEmpty
        Empty={getLoading ? undefined : <Empty text={L["noResultFoundText"]} />}
        empty={items}
       >
        <div className="w-full flex flex-col gap-y-[5.5vw] md:gap-y-10">
         {lefts.map((item) => {
          return <Place key={item._id + "Place"} item={item} />;
         })}
         <PlacePlaceHolder />
        </div>
        <div className="w-full flex flex-col gap-y-[5.5vw] md:gap-y-10">
         {rights.map((item) => {
          return <Place key={item._id + "Place"} item={item} />;
         })}
         <PlacePlaceHolder />
        </div>
       </WithEmpty>
      </SkipUpdate>
     </WithLoading>
    </div>
    <WithLoading
     loadingTrail={1000}
     Loading={
      <Transition
       appear
       show={getLoading}
       enter="transition duration-100 ease-out"
       enterFrom="transform scale-95 opacity-0"
       enterTo="transform scale-100 opacity-100"
       leave="transition duration-1000 ease-out"
       leaveFrom="transform scale-100 opacity-100"
       leaveTo="transform scale-95 opacity-0"
      >
       <div className="fixed left-1/2  bottom-[15vw] mx-auto w-[5vw]">
        <Spinner />
       </div>
      </Transition>
     }
     loading={getLoading}
    ></WithLoading>
   </div>
  </div>
 );
};

export default SearchPage;
