import React from "react";
import {
 ToastContainer,
 cssTransition,
 ToastContainerProps,
} from "react-toastify";
import ReactDOM from "react-dom";
import { useScrollToTopByPathChange } from "../hook/useScrollTopbyPath";
//  enter 와 exit 의 값은 css 에니메이션 이름입니다.
const Zoom = cssTransition({
 enter: "zoomIn",
 exit: "zoomOut",
});

export interface IProps extends ToastContainerProps {}

export const Tostify: React.FC<IProps> = ({ ...props }) => {
 useScrollToTopByPathChange();

 if (typeof window === "undefined") return null;
 const container = document.getElementById("App");
 if (!container) return null;

 return ReactDOM.createPortal(
  <ToastContainer
   position={"bottom-center"}
   transition={Zoom}
   {...props}
   closeOnClick
   autoClose={3000}
  />,
  container!
 );
};

export default Tostify;
