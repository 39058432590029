interface IProp extends React.SVGProps<SVGSVGElement> {
 unCheck?: boolean;
}

export const UnCheckBox: React.FC<IProp> = ({ ...props }) => {
 return (
  <svg
   xmlns="http://www.w3.org/2000/svg"
   width="24"
   height="24"
   viewBox="0 0 24 24"
   fill="none"
   {...props}
  >
   <rect
    x="2.75"
    y="2.75"
    width="18.5"
    height="18.5"
    rx="4.25"
    stroke="white"
    stroke-width="1.5"
   />
   <circle cx="20" cy="20" r="4" fill="#FBBF24" />
  </svg>
 );
};

export const CheckBox: React.FC<IProp> = ({ ...props }) => {
 if (props.unCheck) return <UnCheckBox {...props} />;
 return (
  <svg
   xmlns="http://www.w3.org/2000/svg"
   width="24"
   height="24"
   viewBox="0 0 24 24"
   fill="none"
   {...props}
  >
   <rect x="2" y="2" width="20" height="20" rx="5" fill="#FBBF24" />
   <path
    d="M8 12L11 15L16 9"
    stroke="white"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
   />
  </svg>
 );
};
