import { FfileFragment } from "../app/apollo/type/graphql";

interface IProp
 extends React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
 > {
 file?: FfileFragment | null;
 size?: string | number;
}

export const Photo: React.FC<IProp> = ({ file, src, size, ...props }) => {
 const originalSrc = src || file?.uri;
 const isVideo = false;

 const sizedUrl =
  originalSrc && size ? getResize(originalSrc, size) : originalSrc;
 return (
  <img
   onError={(e) => {
    if (!originalSrc) return;
    if (e.currentTarget.src !== originalSrc) e.currentTarget.src = originalSrc!;
   }}
   {...props}
   src={sizedUrl}
  />
 );
};

function getResize(url: string, size: string | number) {
 // This regex matches the last part of the URL that might include a pattern like '---1500'
 // and might end with a file extension like '.jpg'. It captures the base name and the extension separately.
 const regex = /(?:---\d+)?(\.[^.\/]+)?$/;

 // Replace the matched part with '---{number}' and keep the original extension if it exists.
 // If the URL does not end with an extension, it will just append '---{number}'.
 return url.replace(regex, "---" + size + "$1");
}
