import classNames from "classnames";
import { ReactNode, useState } from "react";
import { twMerge } from "tailwind-merge";
import { removeChar, autoHypen, autoComma } from "../utils/format";

type onChangeEvent = React.HTMLProps<HTMLInputElement>["onChange"];

interface IProp
 extends Omit<
  React.HTMLProps<HTMLInputElement>,
  "size" | "label" | "onChange"
 > {
 size?: "S" | "L";
 formatFn?: (val: any) => any;
 label?: ReactNode;
 comma?: boolean;
 hyphen?: boolean;
 numberOnly?: boolean;
 value?: number | string;
 trim?: boolean;
 onChange?: (value: any, e: onChangeEvent) => void;
 varaint?: "input";
 Icon?: any;
 inputClassname?: string;
}

export const Input: React.FC<IProp> = ({
 formatFn,
 value = "",
 size = "L",
 trim,
 label,
 hyphen,
 comma,
 varaint,
 numberOnly,
 onChange,
 onBlur,
 Icon,
 onFocus,
 className,
 inputClassname,
 ...props
}) => {
 const [inputState, setInputState] = useState<{ focus: boolean }>({
  focus: false,
 });

 const inputClassnames = twMerge(
  classNames(
   "rounded w-full placeholder-neutral-200 focus:border-indigo-500 outline-none text-neutral-800 bg-white border-neutral-200   border  px-3 text-neutral-800 ",
   {
    "h-8 text-xs font-light": size === "S",
    "h-11 text-sm font-normal": size === "L",
    "text-neutral-500 ": !value,
    "border-t-0 border-x-0 rounded-none px-1 h-10": varaint === "input",
   },
   inputClassname
  )
 );

 const labelClassnames = twMerge(
  classNames("mb-1 text-neutral-500 font-light", {
   "text-indigo-500": inputState.focus,
  })
 );

 const handleChange = (value: string, e: onChangeEvent) => {
  if (onChange) {
   onChange(value, e);
  }
 };

 const defaultValueFormat = (inValue: any) => {
  let inInValue = inValue;
  if (trim) return removeChar(inInValue, " ");
  if (hyphen) return autoHypen(inInValue);
  if (comma) return autoComma(inInValue);
  if (numberOnly) return parseInt(inInValue);
  return inInValue;
 };
 const valueFormat = formatFn || defaultValueFormat;

 return (
  <div className={classNames("relative w-full", className)}>
   {label && <p className={labelClassnames}>{label}</p>}
   <input
    value={valueFormat(value)}
    onChange={(e) => {
     handleChange(e.currentTarget.value, e as any);
    }}
    onBlur={(e) => {
     setInputState({ focus: false });
     onBlur && onBlur(e);
    }}
    onFocus={(e) => {
     setInputState({ focus: true });
     onFocus && onFocus(e);
    }}
    className={inputClassnames}
    {...props}
   />
   {Icon && (
    <span
     className={classNames(
      "absolute  right-1 top-0 bottom-0 flex items-center",
      {
       "text-indigo": inputState.focus,
      }
     )}
    >
     {Icon}
    </span>
   )}
  </div>
 );
};
